import React from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";
import { Link } from "react-router-dom";

import MobileStoreButton from "react-mobile-store-button";
import ContainsArea from "../../Components/ContainsArea/ContainsArea";

import bgimg from "../../assets/images/IZB/IZB Website Skin/IZB Save Plus Account/image_6.png";
import { AiFillCaretRight, AiOutlineRight } from "react-icons/ai";
import { useEffect } from "react";
import VideoSlider from "../../Components/VideoSlider/VideoSlider";


const MobileBanking = () => {
  const img1 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/24-7 1.svg";
const img4 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/money-transfer.png";
const img5 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/indo-mobile-banking-money.png";
const img6 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/online-banking.png";
const bannerPhones = "https://izbassetsmain.blob.core.windows.net/izbwebassets/Group 1000003036.png";
const BackgroundBrundcrumb = "https://izbassetsmain.blob.core.windows.net/izbwebassets/indo-mobile-banking-banner.png";
const img = "https://izbassetsmain.blob.core.windows.net/izbwebassets/indo-mobile-banking2.png";
const Logo = "https://izbassetsmain.blob.core.windows.net/izbwebassets/Group 402.png";
const Logo1 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/Group 403.png";
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const textColor = {
    color: "#9E1B1E",
  };
  const iosUrl =
    "https://apps.apple.com/us/app/izb-retail-banking/id1507943733";
  const androidUrl =
    "https://play.google.com/store/apps/details?id=com.izb&hl=en&gl=US";
  const content =
    "Indo Mobile Banking gives you access to your account on your mobile/tab device. Perform your banking tasks from the palm of your hand, from anywhere and at anytime!";
  return (
    <>
      <Breadcrumb Heading="Indo  Mobile Banking" img={BackgroundBrundcrumb} />
      <section className="overview-area">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-xl-5">
              <div className="overview-content-box-one">
                <img src={img} width="100%" alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-md-6 ">
              <div className="overview-content-box-two h-100 d-flex flex-column justify-content-center">
                <div className="inner-title">
                  <h5>Know About</h5>
                  <h2>Indo Mobile Banking</h2>
                </div>
                <div className="text">
                  <p>{content}</p>
                </div>
                <div className=" align-items-center">
                <Link to={androidUrl} target="_blank" >
                    <img src={Logo} width={150} style={{textDecoration:"none", border:"none"}} />
                  </Link>
                    <Link to={iosUrl} target="_blank">
                    <img src={Logo1} width={150} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContainsArea />

      <section
        id="benefits"
        class="benefits-style2-area"
        className="choose-style1-area"
      >
        <div className="container pt-4">
          <div className="text-center my-5">
            <h1 style={{ color: "#484848" }}>INDO Moblie Banking Benefits</h1>
            <p style={{ color: "#484848" }}>
              We help Businesses and customers achieve more.
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <ul className="row choose-style1__content">
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={img4} alt="" />
                  </div>
                  <div className="text">
                    <h5>Money Transfers</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={img6} alt="" />
                  </div>
                  <div className="text">
                    <h5>Bank To wallet transfers</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={img5} alt="" />
                  </div>
                  <div className="text">
                    <h5>Pay Bills and Top up Airtime</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={img1} alt="" />
                  </div>
                  <div className="text">
                    <h5>Account Statement Requests & much more...</h5>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

       <section
        id="eligibility"
        class="eligibility-area"
        style={{ backgroundColor: "#FFF5E7" }}
      >
        <div className="container-fluid">
          <div className="sec-title text-center">
            <h2>How to Sign Up for Mobile Banking</h2>
            {/* <div className="sub-title">
              <p>Basic documents required for opening a savings account.</p>
            </div> */}
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12">
                {/*Start Single Documents Box*/}
                <div
                  className="single-documents-box h-100"
                  style={{ backgroundColor: "#9E1B1E" }}
                >
                  <ul>
                    <li>
                      <div className="inner-title">
                        <p className="text-white">
                          Go to your phones PlayStore/App Store and search for
                          IZB Retail Banking
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white">
                          Download and install the IZB retails retail banking
                          app
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white">
                          Open the application and click on GetStarted to open
                          the login page
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white">
                          Click on the sign up tab and enter the required
                          details in the fields provided
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white">
                        Accept the terms and conditions and Click the submit button
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white">
                          Setup your Desired USERNAME, and Password in the
                          second screen and submit
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white lh-base">
                          After setting up your USERNAME and Password an
                          activation link will be sent toyour registered mobile
                          number and email. Open the activation link to activate
                          youraccount and login to transact.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                {/*End Single Documents Box*/}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="overview-area">
        <div className="container">
          <div className="row">
            <div
              className=" col-md-6 col-xl-6"
            >
              <div className="overview-content-box-one text-center">
                <img
                  src={bannerPhones}
                  width="360px"
                  alt=""
                />
              </div>
            </div>
            <div className="col-xl-6 col-md-6 ">
              <div className="overview-content-box-two h-100 d-flex flex-column justify-content-center">
                <div className="inner-title">
                  <h2 style={textColor}>INDO MOBILE BANKING</h2>
                  <div className="d-flex align-items-center gap-3 my-3">
                    <img src={img1} width={50} alt="" />
                    <h5 style={textColor}>Anytime, Anywhere.</h5>
                  </div>
                  <p>
                    Indo Mobile Banking gives you access to your account on your
                    mobile/tab device. Perform your banking tasks from the palm
                    of your hand, from anywhere and at anytime!
                  </p>
                  <h3 className="py-3" style={textColor}>
                    USSD Dial *232#
                  </h3>
                </div>
                <div className=" align-items-center">
                <Link to={androidUrl} target="_blank" >
                    <img src={Logo} width={150} style={{textDecoration:"none", border:"none"}} />
                  </Link>
                    <Link to={iosUrl} target="_blank">
                    <img src={Logo1} width={150} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

<VideoSlider />
      <AllAcountsSection />
    </>
  );
};

export default MobileBanking;
