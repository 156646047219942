import React from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";
import {
  AiFillCaretRight,
  AiOutlineArrowRight,
  AiOutlineArrowUp,
} from "react-icons/ai";
import { useState } from "react";
import SavePlusAccountComponent from "../../Components/SavePlusAccount/SavePlusAccountComponent";
import ContainsArea from "../../Components/ContainsArea/ContainsArea";

import { useEffect } from "react";


const IndoVehicleLoan = () => {
  const img1 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/indo-vehicle-loan-1.png";
const BackgroundBrundcrumb = "https://izbassetsmain.blob.core.windows.net/izbwebassets/indo-vehicle-loan-banner.png";
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const accountContent =
    "With our Indo Vehicle Loans you dream car can be a reality. Bring your dreams to life with competitive interest rates and low processing fees, making it easy for you to access the financing you need to achieve your dream. ";
  const [show, setShow] = useState(false);
  return (
    <>
      <Breadcrumb Heading="Indo Vehicle Loan" img={BackgroundBrundcrumb} show={true} btnTxt="Apply Now" />
      <SavePlusAccountComponent
        heading="Indo Vehicle Loan"
        subHeading="Know About"
        btnTxt="Apply Now"
        content={accountContent}
        img={img1}
        logo={true}
      />
      <ContainsArea />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-xl-12 justify-content-center">
              <div className="faq-style1__content">
                <ul className="accordion-box mt-5">
                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Eligibility
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Permanent/contractual employees.
                        </p>
                      )}
                     
                     {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Joint applications allowed with immediate family
                        </p>
                      )}

                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Entities including government department, companies, partnerships, NGOs’s and
                          proprietorship.
                        </p>
                      )}


                    </div>
                  </li>
                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Purpose
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Financing for brand-new or second-hand vehicles for personal use, company use or use
by personnel/employees/directors of the company Electric vehicles, superbikes, and water
vehicles.
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          To finance the purchase of Electric Vehicles, Superbikes, Water vehicles such as
motorboats, sports boats and other watercraft for personal use, company use or use by
personnel/employees/directors of the company

                        </p>
                      )}

                       {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Takeover of existing vehicle loans (conditions apply)


                        </p>
                      )}

                    {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Reimbursement available for self-financed vehicles not exceeding six (6) months old
                          (conditions apply)
                        </p>
                      )}
                    </div>
                  </li>


                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                      Loan Amount & Contribution:
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                         <span className="">
                         <AiFillCaretRight className="position-absolute mt-2" style={{marginLeft:"48px"}} />

                         <p className="" style={{paddingLeft:"70px"}}>
                         No upper limit, based on debt ratio
                         </p>
                       </span>

                      )}

{show && (
                         <span className="">
                         <AiFillCaretRight className="position-absolute mt-2" style={{marginLeft:"48px"}} />

                         <p className="" style={{paddingLeft:"70px"}}>
                         Contribution: <span>15% upfront for new vehicles.</span>
                         </p>
                       </span>

                      )}
                      
                    </div>
                  </li>











                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Security
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                         <span className="">
                         <AiFillCaretRight className="position-absolute mt-2" style={{marginLeft:"48px"}} />

                         <p className="" style={{paddingLeft:"70px"}}>
                         Charge on vehicle purchased out of bank ﬁnance.
                         </p>
                       </span>

                      )}
                      
                    </div>
                  </li>




                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Customer Contribution
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Minimum margin of 30% upfront.
                        </p>
                      )}
                    </div>
                  </li>
                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Repayment Terms{" "}
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Maximum 6 years for new four-wheelers, 3 years for two-wheelers.

                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          5 years for second-hand vehicles.
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Flexible repayment aligned with income cycles.
                        </p>
                      )}
                    </div>
                  </li>





                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                      Interest Rates{" "}
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Competitive rates in Zambian Kwacha or USD.

                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Discounts available for higher upfront contributions or electric vehicles.
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Lower rates applicable for female primary borrowers

                        </p>
                      )}
                    </div>
                  </li>


                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                      Turn around Time{" "}
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Within 72 hours of submission of final document.


                        </p>
                      )}
                      
                      
                    </div>
                  </li>



                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                      Contact Details{" "}
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Visit any of our branches, contact us on 8002 or email <a href="mailto:vaf@izb.co.zm">vaf@izb.co.zm</a>


                        </p>
                      )}
                      
                      
                    </div>
                  </li>









                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active mb-3"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                      Terms and Conditions Apply
                      </h3>
                    </div>
                  
                  </li>
                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active mb-3"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                      Reputable Dealers – Southern Cross motors and Action Auto”
                      </h3>
                    </div>
                  
                  </li>
                </ul>
              </div>
            </div>
          
          </div>
        </div>
      </section>
      <AllAcountsSection />
    </>
  );
};

export default IndoVehicleLoan;
