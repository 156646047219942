import React,{useRef} from "react";
import { useEffect } from "react";
import Breadcrumb1 from "../../Components/Breadcrumb1/Breadcrumb1";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";


// import BackgroundBrundcrumb from "../../assets/images/IZB/BackgroundBrundcrumb.png";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineShareAlt,
} from "react-icons/ai";

import { Link } from "react-router-dom";
import { CiClock1 } from "react-icons/ci";
import { BiMessageDetail } from "react-icons/bi";
const SaleOfProperties = () => {
const BackgroundBrundcrumb = "https://izbassetsmain.blob.core.windows.net/izbwebassets/BackgroundBrundcrumb.png";
const storie1 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/11.png";
const storie2 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/RegistrationofSuppliers.png";
const storie3 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/12.png";
const storie51 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/1312.png";
const storie11 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/IZB-TENDER-BRANCH-FITOUT-PRESS-AD-25X4-RVS-V2.png";
const storie4 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/13.png";
const storie5 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/14.png";
const page1 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/SaleOfProperties.png"
const arroww = "https://izbassetsmain.blob.core.windows.net/izbwebassets/chevron_right.svg";

  const sliderRef = useRef(null);
  console.log(sliderRef.current);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb1 Heading="Sale Of Properties" Heading1 ="Indo Zambia Bank  |  December 07, 2023" img={BackgroundBrundcrumb} />
      <div className="text-center" style={{margin:"3rem"}}>
        <img src={page1} alt="" />
        {/* <p style={{fontSize:"20px", margin:"2vw"}}>We wish to notify our esteemed customers that the Bank of Zambia increased the Monetary Policy Rate (MPR) from 9.50% to 10%.
</p> */}
      </div>
      <section>
      <div className="container">
        <div className="row">
          <div className="container">
            <div className="heading ">
              <h2 className="text-left" style={{color:"#9E1B1E",fontWeight:"450"}}>More to Explore</h2>
             
            </div>
            <div
              className="py-4"
              style={{ float: "right"}}
            >
         
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
                <a href="/VendorsSuppliers2024">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie2} alt="" />
           
                    <div className="overlay-icon"></div>
              
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  {/* <a>THE OFFICIAL LAUNCH OF THE NEW INDO HOUSE CORPORATE HEAD OFFICE</a><br/> */}
                  <a>Registration Of Vendors / Suppliers 2024
</a><br/><br/>

                  <span style={{fontSize: "15px", color: "gray"}}>December 06, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                {/* Indo Zambia Bank is proud to announce the launch of the provision of the E-Workers on our INDO Internet Banking platform .This INDO Net Banking platform ... */}
                Indo Zambia Bank is in the process of registering suppliers for the provision of goods and services to the Bank for the year 2024.Applications are therefore being ...
                </p><br/>
                <Link
                to="/VendorsSuppliers2024">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              <a href="/Monetarypoliceratenovember">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie11} alt="" />
                
                    <div className="overlay-icon"></div>
                 
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  {/* <a>MONETARY POLICY RATE</a><br/> */}
                  <a>CUSTOMER NOTICE<br/>Monetary Policy Rate (MPR)<br/></a><br/>

                  <span style={{fontSize: "15px", color: "gray"}}>November 27, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                We wish to notify our esteemed Customers that the Bank of Zambia during
the 20th 21st November, 2023 MPC Quaterly Meeting, increased the
Monetary Policy ...
                </p><br/>
                <Link
                to="/Monetarypoliceratenovember">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
  
          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="/Tenderforfitoutworks">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie51} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a>Invitation to Tender for the Fitout works of proposed IZB Branches</a><br/>
                  <span style={{fontSize: "15px", color: "gray"}}>November 14, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                  Indo Zambia Bank intends to undertake the fitout works of its selected branches and hereby invites sealed bids from eligible contractors registered with National ...
     
                </p><br/>
                <Link
                to="/Tenderforfitoutworks">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </section>
      <AllAcountsSection />
    </>
  );
};

export default SaleOfProperties;
