import React from 'react'
import { useState,useEffect } from 'react';
import { AiOutlineArrowRight, AiOutlineArrowUp } from 'react-icons/ai';
import { ReactComponent as ArrowR } from "../../assets/images/IZB/arrow_right.svg";



const PrivacyAccordion = ({title,description}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    const [show6, setShow6] = useState(false);
    const [show7, setShow7] = useState(false);
    const [show8, setShow8] = useState(false);
    const [show9, setShow9] = useState(false);
    const [show10, setShow10] = useState(false);
    const [show11, setShow11] = useState(false);
    const [show12, setShow12] = useState(false);
    const [show13, setShow13] = useState(false);
    const [show14, setShow14] = useState(false);
    const [show15, setShow15] = useState(false);


    return (
      <>
      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow(!show)}>
          Acceptance
          </h3>
        </div>
        <div className="acc-content current">
          {show && (
            <p>
               <ArrowR />
               These terms and conditions of use become effective when the user accesses this site for the first time and constitute a binding agreement between INDO-ZAMBIA BANK LIMITED and/ or its subsidiaries or affiliates, as the case may be and the user.
            </p>
            
          )}
        </div>
      </li>

      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show1 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow1(!show1)}>
          Links to Third Party Sites
          </h3>
        </div>
        <div className="acc-content current">
          {show1 && (
            <p>
               <ArrowR />
               This site may contain links to web sites controlled or offered by third parties. INDO-ZAMBIA BANK LIMITED hereby disclaims liability for, any information, materials, and products or services posted or offered at any of the third party sites linked to this web site. By creating a link to a third party web site, INDO-ZAMBIA BANK LIMITED does not endorse or recommend any products or services offered, or information contained on that web site and is merely providing these links to you only as a convenience, nor is INDOZAMBIA BANK LIMITED liable for any failure of products or services offered or advertised on those sites. Such third party may have a privacy policy different from that of INDO-ZAMBIA BANK LIMITED and the third party website may provide less security than the INDO-ZAMBIA BANK LIMITED website.
            </p>
            
          )}
        </div>
      </li>

      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show2 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow2(!show2)}>
          User's Liability to INDO-ZAMBIA BANK LIMITED
          </h3>
        </div>
        <div className="acc-content current">
          {show2 && (
            <p>
               <ArrowR />
               The user shall be liable to INDO-ZAMBIA BANK LIMITED for any liabilities, losses or expenses incurred by INDO-ZAMBIA BANK LIMITED as a result of any breach by the user of these terms and conditions.
            </p>
            
          )}
        </div>
      </li>


      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show3 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow3(!show3)}>
          Use of Materials and Information
          </h3>
        </div>
        <div className="acc-content current">
          {show3 && (
            <p>
               <ArrowR />
               The information and materials contained in these pages, and the terms, conditions, and descriptions that appear, are subject to change. Unauthorized use of INDO-ZAMBIA BANK LIMITED websites and systems. including but not limited to unauthorized entry into INDO-ZAMBIA BANK LIMITED systems, misuse of passwords, or misuse of any information posted on a site is strictly prohibited. Your eligibility for particular services is subject to final determination by INDO-ZAMBIA BANK LIMITED.
            </p>
            
          )}
        </div>
      </li>



      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show4 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow4(!show4)}>
          Intellectual Property Rights
          </h3>
        </div>
        <div className="acc-content current">
          {show4 && (
            <p>
               <ArrowR />
               This web site contains copyright and other intellectual property including logos and other graphics and multimedia works belonging to INDO-ZAMBIA BANK LIMITED. <br/>
               <ArrowR />
       
        The user is authorised to view and download, copy to a local hard drive or disk, print and make copies of such printouts, provided that The material is used for considering use of the online services and for no other commercial purposes.<br/>
        <ArrowR />
        
        Any reproduction of our proprietary material from this site or portion of it, must include INDO-ZAMBIA BANK LIMITED’s copyright notice in its entirety.<br/>
        <ArrowR />
        
        The logos and trademarks shown on this site are INDO-ZAMBIA BANK LIMITED’s registered and unregistered trademarks, or that of third parties.<br/>
        <ArrowR />
       
        All content included on the Sites, including but not limited to text, graphics, photographs, video and audio clips, streaming data, animation, images, downloadable materials, data compilations and software is the property of the INDO-ZAMBIA BANK LIMITED or its content suppliers and is protected by applicable copyright and trademark laws.<br/>
        <ArrowR />
        Nothing on this site should be construed as granting any licence or right to use any trademark without INDO-ZAMBIA BANK LIMITED’s prior written permission and/or that of third parties, as the case may be. The user may not, without INDO-ZAMBIA BANK LIMITED’s prior written consent, use INDO-ZAMBIA BANK LIMITED’s intellectual property or that of third parties for any purposes whatsoever.
            </p>
            
          )}
        </div>
      </li>



      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show5 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow5(!show5)}>
          No Warranty
          </h3>
        </div>
        <div className="acc-content current">
          {show5 && (
            <p>
               <ArrowR />
              The information and materials contained in this site, including text, graphics, links or other items are provided ‘as is’ and ‘as available’. INDO-ZAMBIA BANK LIMITED does not warrant the accuracy, adequacy or completeness of this information and materials and expressly disclaims liability for errors or omissions in this information and materials. No warranty of any kind, implied, expressed or statutory including but not limited to the warranties of non-infringement of third party rights, title, merchantability, fitness for a particular purpose and freedom from computer virus, is given in conjunction with the information and materials.<br/>
              <ArrowR />We do not warrant or represent that access to the whole or part(s) of this website, the materials, information and/or functions contained therein will be provided uninterrupted, timely, secure or error-free or that any identified defect will be corrected, or that there will be no delays, failures, errors or loss of transmitted information, that no viruses or other contaminating or destructive properties will be transmitted or that no damage will occur to your computer system. The user understands and agrees that any material and/or data downloaded or otherwise obtained through the use of the Sites or any of the materials contained therein is done at the User’s own discretion and risk. If the Users use of the Sites or the Material results in the damage and need for servicing or replacing equipment or data, we shall not be responsible for those costs and the user will solely be responsible for any such damages. If the Material is provided by third parties, we shall not be held responsible for any such third party material.<br/>
              <ArrowR />The materials, information and functions provided in the Sites shall not under any circumstances be considered or construed as an offer or solicitation to sell, give, issue or as the giving of any advice in respect of any form of investment or other securities, loans, or deposits in any jurisdiction. The User shall be responsible for evaluating the quality, adequacy, timeliness and usefulness of all services, content, advice, opinions and other information obtained or accessible through the Sites; further the User should seek professional and/or legal advice at all times and obtain independent verification of the materials and information provided herein prior to making any investment, business or commercial decision based on any such materials or information
            </p>
            
          )}
        </div>
      </li>




      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show6 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow6(!show6)}>
          Amendments to these conditions
          </h3>
        </div>
        <div className="acc-content current">
          {show6 && (
            <p>
               <ArrowR />
               INDO-ZAMBIA BANK LIMITED may at its discretion amend these conditions from time to time without notification to the user. By accessing this site the Users are bound to the version of the terms and conditions published at the time of any visit to this site. The User agrees to view the current version each time that this site is visited.<br/>
               <ArrowR />
        The current version of these conditions will govern the respective rights and obligations of INDO-ZAMBIA BANK LIMITED and the User each time the User access this site.
            </p>
            
          )}
        </div>
      </li>




      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show7 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow7(!show7)}>
          Capacity to Enter into Agreements
          </h3>
        </div>
        <div className="acc-content current">
          {show7 && (
            <p>
               <ArrowR />
               The User hereby warrants to INDO-ZAMBIA BANK LIMITED that the User has the required legal capacity to enter into and be bound by these terms and conditions.<br/>
               <ArrowR />
        Minors must be assisted by their legal guardians when reading these terms and conditions.
            </p>
            
          )}
        </div>
      </li>




      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show8 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow8(!show8)}>
          Sending of Information
          </h3>
        </div>
        <div className="acc-content current">
          {show8 && (
            <p>
               <ArrowR />
               Information transmitted through an unsecured link over the Internet, including e-mail, is subject to potential unlawful access or monitoring.<br/>
               <ArrowR />
        The User accepts that INDO-ZAMBIA BANK LIMITED cannot be held liable for any loss, or damage suffered by the User as a result of unlawful activities by unscrupulous persons, as INDO-ZAMBIA BANK LIMITED cannot prevent such behaviour.
            </p>
            
          )}
        </div>
      </li>




      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show9 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow9(!show9)}>
          Disclaimer and Limitation of Liability
          </h3>
        </div>
        <div className="acc-content current">
          {show9 && (
            <p>
               <ArrowR />
               The use of this site and the online services is entirely at the User’s own risk. The User assumes full responsibility for the risk or loss resulting from the use of this site and the User’s reliance on information contained on it.<br/>
               <ArrowR />
        In no event will INDO-ZAMBIA BANK LIMITED be liable for any damages, whether direct, indirect, special, incidental, or consequential whatsoever relating to the User’s use of this site or the online services or the information contained on this site or the User’s inability to use this site or the online services, whether such damages arose out of contract, or delict or otherwise and regardless of whether INDO-ZAMBIA BANK LIMITED was expressly advised of the possibility of such loss or damage.<br/>
        <ArrowR />
        Without derogating from the generality of the above, and to the extent legally permitted, INDO-ZAMBIA BANK LIMITED will not be liable for<br/>
        <ArrowR />
        Any interruption, malfunction, downtime, off-line situation or other failure of the site or online services, INDO-ZAMBIA BANK LIMITED’s system, databases or any of its components, beyond INDO-ZAMBIA BANK LIMITED’s reasonable control<br/>
        <ArrowR />
        Any loss or damage with regard to the user’s data or other data directly or indirectly caused by malfunction of INDO-ZAMBIA BANK LIMITED’s system, third party systems, power failures, unlawful access to or theft of data, computer viruses or destructive code on INDO-ZAMBIA BANK LIMITED’s system or third party systems; programming defects<br/>
        <ArrowR />
        Any interruption, malfunction, downtime or other failure of goods or services provided by third parties, including, without limitation, third party systems such as the public- switched telecommunication service providers, internet service providers, electricity suppliers, local authorities and certification authorities; or any event over which the INDO-ZAMBIA BANK LIMITED has no direct control<br/>
        <ArrowR />
        Access to this Web Site is at the User’s own risk. If a User is dissatisfied with any of the contents of the Sites or these Terms and Conditions, a User’s sole remedy is to discontinue use of this site.
            </p>
            
          )}
        </div>
      </li>




      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show10 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow10(!show10)}>
          Sending of Information
          </h3>
        </div>
        <div className="acc-content current">
          {show10 && (
            <p>
               <ArrowR />
               INDO-ZAMBIA BANK LIMITED may use the services of other third party organisations to provide information on this site. INDO-ZAMBIA BANK LIMITED has no control over the third party information and makes no representations or warranties of any nature as to its accuracy, appropriateness or correctness. INDO-ZAMBIA BANK LIMITED will not be directly or indirectly liable for any damages that may arise from the User’s reliance on it.
            </p>
            
          )}
        </div>
      </li>




      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show11 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow11(!show11)}>
          Indemnity
          </h3>
        </div>
        <div className="acc-content current">
          {show11 && (
            <p>
               <ArrowR />
               The User undertakes to defend, indemnify and hold harmless the INDO-ZAMBIA BANK LIMITED, its independent contractors and agents, from and against any and all claims, actions, liabilities, costs, or demands, including without limitation legal and accounting fees, for all damages directly, indirectly, and/or consequentially resulting or allegedly resulting from the users authority including without limitation to governmental agencies, use, misuse, or inability to use the Sites or any of the Materials/Contents contained therein, or the Users breach of any of these Terms and Conditions or any intellectual property right or proprietary right infringement claim made by a third party against the INDOZAMBIA BANK LIMITED in connection with the User’s use of this website. INDO-ZAMBIA BANK LIMITED shall notify the User of any such claim or suit, and the User shall be responsible for its defence. The INDOZAMBIA BANK LIMITED may participate in the defence of such claim or defence but is not obligated to do so
            </p>
            
          )}
        </div>
      </li>




      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show12 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow12(!show12)}>
          Governing Law and Jurisdiction
          </h3>
        </div>
        <div className="acc-content current">
          {show12 && (
            <p>
               <ArrowR />
               These terms and conditions and all matters arising out of or relating to these Terms and Conditions shall be governed by and are to be construed in accordance with the laws of Zambia, excluding any conflict of law provisions. By accessing this website and/or applying for the products or services provided herein by the INDO-ZAMBIA BANK LIMITED, you hereby consent to the exclusive jurisdiction of the Zambian courts in all disputes arising out of or relating to the use of this website.
            </p>
            
          )}
        </div>
      </li>




      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show13 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow13(!show13)}>
          General Provisions
          </h3>
        </div>
        <div className="acc-content current">
          {show13 && (
            <p>
               <ArrowR />
               The headings of the clauses in the conditions is provided for convenience and ease of reference only and will not be used to interpret, modify or amplify the terms of the conditions.<br/>
               <ArrowR />
        No failure or delay by INDO-ZAMBIA BANK LIMITED to exercise any of INDO-ZAMBIA BANK LIMITED’s rights will be construed as a waiver of any such right, whether this is done expressly or impliedly, nor will it affect the validity of any part these terms and conditions or prejudice our right to take subsequent action against the user.<br/>
        <ArrowR />
        If any provision of these terms and conditions is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these terms and conditions, which shall remain in full force and effect.
            </p>
            
          )}
        </div>
      </li>




      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show14 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow14(!show14)}>
          IZB DIGITAL BANKING TERMS & CONDITIONS
          </h3>
        </div>
        <div className="acc-content current">
          {show14 && (
            <p>
               <ArrowR />
               TERMS OF USE<br/>
               Please read the following Terms and Conditions carefully. These are the terms and conditions upon which you may use the Digital Banking Channels provided by Indo Zambia Bank Limited (IZB). By registering for Digital Banking through the branch, USSD, App, online or any other available method, you acknowledge that you have read the Terms and Conditions and that you accept and will be bound by them, as they may be modified by the Bank from time to time. If you do not agree to the Terms and Conditions of use, please do not proceed to register for and/or use any IZB Digital Banking channels.<br/>
               <br/> 1.	DEFINITIONS<br/>

a.	'Account' means any account (including wallet) held in the name of the Customer together with any accounts in respect of which the Customer holds a Valid Mandate.<br/>
b.	'Agent (Agency) Banking' means the Bank providing banking services to the Customers through engaged agents under a valid agency agreement as may be approved by the Central Bank. <br/>
c.	'Agent for purposes of Agent Banking' means a person or entity that has been contracted by the Bank to provide banking and related services on behalf of the Bank.<br/>
d.	'Bank or IZB' means Indo Zambia Bank Limited and its successors and assignees. <br/>
e.	'Credentials' means security details such as Username, PIN, Password, Biometric, OTP, etc that allows the Customer to access and/or performance transactions on Digital Banking Channels.<br/>
f.	'Customer' means the actual person or organization that has registered for and uses Digital Banking Channels provided by IZB.<br/>
g.	'Digital Banking Channels' means the methods/channels by which the Bank may from time to time make available banking services to the Customer through mobile phones, tablets, computers, and compatible devices. Examples of Digital Banking Channels are Mobile Banking, Internet Banking, Agency Banking, SMS Banking, Tax Online Payment, NAPSA Online Payment and ZAMPORTAL.<br/>
h.	'Fees/Charges' means the amount payable by the Customer in relation to each transaction done through Digital Banking Channels, as published by the Bank from time to time.<br/>
i.	'Indo Fast Serve' means Agency Banking provided by the Bank.<br/>
j.	'OTP' means One Time Password that is used to authenticate instructions from the Customer.<br/>
k.	'PIN' refers the Personal Identification Number allocated to the Cardholder by IZB, preferred by him/her from time to time. <br/>
l.	'Register' means signing up by completing the Bank's process for a particular Digital Banking Channel or simply by opting to use a particular Digital Banking Channel provided by the Bank.<br/>
m.	'Service Provider' means any of the establishments the Bank contracts directly or indirectly through third parties to provide some services related to providing Digital Banking e.g., ZRA, NAPSA, NHIMA, Universities, Zesco, MTN, Airtel, Zamtel and DSTV.<br/>
n.	'Transaction Receipt' means the paper or electronic record evidencing the transaction. <br/>
<br/>
Digital Banking Channels provided by the Bank will offer the following services; <br/>
a.	Obtaining information relating to the Account, including transactions and standing instruction;<br/>
b.	Transferring funds; <br/>
c.	Paying accounts; <br/>
d.	Requesting from the Bank statements and replies to enquiries which relate to the Account; <br/>
e.	Purchasing airtime; <br/>
f.	Paying Bills;<br/>
g.	Cash deposit;<br/>
h.	Cash withdrawal;<br/>
i.	School/university payments;<br/>
j.	Payment of regulatory obligations such as tax, NAPSA, NHIMA, etc.<br/>
k.	Payment for Government services such as RTSA, Immigration, Zambia Police, PACRA, etc.<br/>
l.	Using any other service, which may be available through Digital Banking Channels provided by the Bank.<br/>
 
<br/>2. USE OF DIGITAL BANKING CHANNELS <br/>

The use of Digital Banking Channels is subject to Terms and Conditions as provided by the Bank. This agreement governs your and our rights and obligations when you use any of the Digital Banking Channels. You will become bound to the most recent version of this agreement when you register to use any of the Digital Banking Channels. Before you can use the Digital Banking Channels you must register for the said Channel. Refer to www.izb.co.zm or the nearest branch for more information on how to register for the different Digital Banking Channels. <br/><br/>
The Customer acknowledges that information transmitted through the Internet or any other communication system, including wireless communication system, is susceptible to unlawful access, distortion and monitoring and that the Customer uses the Digital Banking Channels at its own risk.<br/>
<br/>
The Customer authorizes the Bank to carry out any and all instructions given via the Digital Banking Channels, including the debiting of and transfer of funds from the Account and the furnishing of Account information, provided that the instructions are authenticated by the use of: <br/>
a.	the PIN and/or Password; <br/>
b.	any other procedures which may be agreed to between the Customer and the Bank or may be introduced by the Bank to authenticate Customer instructions.<br/><br/>

The provision of the Digital Banking Channels will not entitle the Customer to overdraw the Account if borrowing arrangements have not been made with the Bank, or to overdraw in excess of any borrowing limit agreed with the Bank. <br/><br/>
The Customer should comply with IZB security tips as published in customer information material from time to time. <br/>
<br/>3. BIOMETRIC <br/>
▪	The IZB Touch ID login service is a service where you may use your fingerprint registered on a permitted mobile device in lieu of your IZB Digital Banking username and password as a security code to confirm your identity to access the Bank’s Digital Banking Channels.<br/>
▪	The IZB Touch ID login service is provided as a convenient login option for Digital Banking Channels.<br/>
▪	You acknowledge and agree that in order to use the IZB Touch ID login service:<br/>
▪	You must be a valid user of our Digital Banking Channels;<br/>
▪	You must install our mobile app using a permitted mobile device;<br/>
▪	You will need to activate the Fingerprint ID recognition function on your permitted mobile device and register at least one of your fingerprints to control access to the permitted mobile device;<br/>
▪	You will be required to undergo a registration process using your IZB online/mobile banking username and password to choose to use the Fingerprints ID you store on your permitted mobile device for accessing our Digital Banking Channels; upon the successful registration process, the fingerprints stored on your permitted mobile device will be credentials;<br/>
▪	You must ensure that only your Fingerprints ID are stored on your permitted mobile device to access the device and you understand that upon the successful registration of your permitted mobile device, any Fingerprint ID that is stored on your permitted mobile device can be used to access mobile banking including access to your accounts; and<br/>
▪	You should ensure the security of the security codes as well as the password or code that you can use to register your Fingerprints ID on the permitted mobile device.<br/>
<br/>4. UNAUTHORIZED USE OF THE CREDENTIALS<br/>
 
The Bank deals with the Customer remotely when using Digital Banking, as such the Customer authorizes the Bank to act on and accept all instructions and transactions that occur after the credentials have been entered or applied. We are entitled to assume that all such transactions have been authorized by you, even if such transactions took place without your knowledge or consent or were not authorized. The Customer must inform the Bank immediately if he/she knows or suspects that his/her credentials have been lost, stolen or may be misused. <br/><br/>
If any unauthorized person obtains the credentials in any manner whatsoever, such a person will be regarded as the Customer’s duly authorized agent with full authority to use the Digital Banking Channels on the Customer’s behalf, unless the Customer is able to prove that the person has obtained the credentials fraudulently or due to the Bank’s negligence.<br/>
<br/>5. INDEMNITY AND WARRANTY BY THE CUSTOMER <br/>
The Customer indemnifies the Bank against any damage, loss and/or consequential damage that the Bank may suffer as a result of incorrect, wrong, unauthorized and/ or wrongful instructions or information given by the Customer or any other person authorized or deemed to be authorized to act on behalf of the Customer. <br/><br/>
The Customer hereby warrants to the Bank that the Customer has the required legal capacity to enter into and be bound by these Terms and Conditions. <br/>
<br/>6. DUTIES OF THE CUSTOMER<br/>
 
Customer must keep his/her credentials safe and secret. The Customer must not disclose the Username, PIN, Password or OTP, thereby giving access to any unauthorized person. <br/><br/>
The Customer must take all reasonable precautions to prevent unauthorized use of the Digital Banking Channels and unauthorized access to the Account. <br/><br/>
If the Customer suspects that the PIN, Password, OTP or any other credentials has become known to someone else, the Customer must immediately notify the Bank and immediately change the compromised credentials. <br/><br/>
The Customer must provide the correct account number as well as all other required beneficiary details, of all beneficiaries when instructing the Bank to make payments, pay accounts and to transfer funds to such beneficiaries. The Bank does not at any stage verify an account number and other details of a beneficiary.<br/>
<br/>7. OTHER AGREEMENTS APPLICABLE<br/>

Certain products and services that you use or access via Digital Banking Channels are subject to their own terms and conditions, such as the bank account or services you access, your ATM Debit card and Prepaid card. This agreement (governing the use of Digital Banking Channels) must be read together with, and forms part of each product agreement. In the event of conflict between this agreement and such other terms, the provisions of such other terms will prevail to the extent of the conflict. <br/>
<br/>8. FEES <br/>

The Customer commits to pay the fees related to Digital Banking Channels determined by the Bank according to the its tariff guide announced by the Bank from time to time. The Bank reserves its right to amend any fees at any time according to its own assessment, and announcing fees in the form the Bank sees fit/adequate. The annunciation is considered a working notification. Using the Digital Banking Channels after amendment date is considered an acceptance from the Customer’s side with no reservations.<br/><br/>

Any fees and charges in respect of the Digital Banking Channels will be debited to the Customer’s nominated bank account. A copy of applicable fees can be obtained from the Banks website www.izb.co.zm or from any of the Bank’s branches. Should the Bank be unable to recover any fees/charges from the nominated bank account on the due date, due to insufficient or for any other reason whatsoever, the Bank shall be entitled to recover such fees/charges as and when the funds may be become available in the customer’s nominated bank account, alternatively from any other IZB’s account belonging to the Customer.<br/><br/>
 
Should the Bank be unable to recover any fees/charges as mentioned above, the Bank reserves the right to suspend the Digital Banking Channel with immediate effect, with or without notice. <br/>
<br/>9. AVAILABILITY OF DIGITAL BANKING CHANNELS<br/>
 
The Digital Banking Channels will be available at all times, subject to the availability of the Internet and Mobile Networks connections and to any periods required for necessary maintenance.<br/><br/>
Should the Digital Banking Channels be unavailable for whatever reason, the Customer must use the Bank’s other available service channels and take reasonable steps to minimize and/or prevent loss or risk.<br/>

<br/>10. INTERNET REQUIREMENTS<br/>

Should the Customer obtain access to the Digital Banking Channels via the Internet, the customer must register with an Internet Service Provider before the commencement date. <br/><br/>
The Customer will be solely responsible for the acquisition, installation and maintenance of the connection to the Internet or any related costs or expenses will be borne by the Customer. <br/><br/>
Should the Internet software and hardware requirements be modified with a view to improving or upgrading the Internet, the resultant cost of any hardware, software or Internet connection required for use of the system will be for the Customer’s own account.<br/><br/>
In the event that the Customer experiences problems with the Internet and Internet connection, it is the Customer’s responsibility to liaise with the Internet Service Providers prior to contacting the Bank.<br/>
<br/>11. MOBILE PHONE REQUIREMENTS<br/>
 
Should the Customer obtain access to the Electronic Banking Channels via the mobile phone, the Customer must register with Mobile Network Operator before the commencement date. <br/><br/>
The Customer will be solely responsible for obtaining of the prescribed mobile phone and the connection to the Mobile Network Operator and any related costs or expenses will be borne by the Customer. <br/><br/>
Should the mobile phone requirements be modified with a view to improving or upgrading the Mobile Banking Services, the resultant costs of any new mobile phone or service provider connection required so as to use the Digital Banking Channels will be for the Customer’s own account. <br/><br/>
In the event that the Customers experiences problems with the mobile phone and/or Mobile Network Operator, it is the Customer’s responsibility to liaise with the Mobile Network Operator prior to contacting the Bank.<br/>
<br/>12. SERVICE PROVIDERS<br/>

The Bank has integrated with various Service Providers for the Customers to access various services provided by them through Digital Banking Channels. The Bank merely acts as a facilitator for the Customers to be able to transact with Service Providers.<br/><br/>
The Customer will be solely responsible for ensuring that they have the ability to transact with the Service Provider and any related costs or expenses will be borne by the Customer. The Customer shall abide by the Terms and Conditions of the Service Provider and it is the Customer’s responsibility to obtain them from the Provider, read and understand the said Terms and Conditions of the Service Provider.<br/><br/>
For any payment/instruction effected by the Bank to a Service Provider beneficiary based on the information received by the Bank from the Customer’s Digital Banking Channel profile registered in the Bank’s record, the Customer shall be solely responsible/liable in any manner whatsoever for any loss, claim, liability as the case may be or incidental thereto.<br/><br/>
In the event that the Customers experiences problems with the Service Provider, it is the Customer’s responsibility to liaise with Service Provider prior to contacting the Bank.<br/>
<br/>13. AGENT BANKING (INDO FAST SERVE)<br/>

The Bank offers Agent Banking as the Digital Banking Channel through which the Bank provides some of its banking services to Customers. The Customer may therefore access some of the banking services that the Bank provides through Indo Fast Serve Agents in various locations across the country. <br/><br/>

By proceeding with an Indo Fast Serve, the Customer agrees to be bound by these Terms and Conditions. <br/><br/>

The Customer should ensure that they confirm all cash received from the Agent before leaving the Agent’s premises after a cash withdrawal transaction.<br/><br/>

The Customer should ensure that the information captured on the Transaction Receipt is accurate before the Customer leaves the Agent’s premises after a cash deposit transaction.<br/><br/>
 
Any concerns should immediately be raised with the Agent with escalations to the Bank if the concern is unresolved by the Agent.<br/><br/>

Ensure that the Agent provides you with a Transaction Receipt for every transaction done before you leave the Agent’s premises.<br/><br/>

If the Customer’s Indo Fast Serve transaction is not successfully completed and the Customer is unable to obtain the service but there is a debit on the Customer’s bank account or there is an error in the amount resulting either from an Agent or system fault, the Customer must immediately resolve the unsuccessful or erroneous transaction with the Agent. And if the Agent is unable to resolve the matter, the Customer must immediately inform the Bank of the unsuccessful or erroneous transaction and in any case not later than (24) hours. The Customer shall provide any information reasonably requested by the Bank to ascertain the status of the transaction and run the required investigations.<br/><br/>

The Bank will follow up all complaints escalated for closure within the time and in accordance with the Bank’s complaints resolution framework.<br/><br/>
If the Customer has received cash from an Agent that is more than what he/she input for your Indo Fast Serve transaction, the Customer shall immediately notify the Bank and cooperate with the Bank on any of the Bank’s requests regarding any investigation.<br/><br/>

The Agent may with the Customer’s consent obtain some personal data to conclude some transactions. Such personal data obtained will strictly be used for purposes of Agent Banking services only. All personal data obtained by the Agents will be handled in accordance with the Data Protection Laws. <br/><br/>

Each successful Indo Fast Serve transaction will have a transaction reference number attached to it. The transaction reference number and the date, time, amount, and status of the Customer’s Indo Fast Serve transaction will be disclosed to and used by the Agent for the purposes of: (a) preparing reports; and (b) reconciling the number of successful Indo Fast Serve transactions performed against the amount that we must pay to the Agent for settlement.<br/>

<br/>14. LIABILITY FOR LOSS OR DAMAGE<br/>

The Customer acknowledges that the use of the Digital Banking Channels and the Account is at the Customer’s own risk. <br/><br/>
It is the Customer’s responsibility to ensure that Digital Banking application is compatible with his/her mobile phone/laptop/PC/device. For whatever damage or loss, if any, incurred by the Customer due to usage of the Bank’s Digital Banking Channels in his/her phone/laptop/PC/device, he/she shall be solely responsible. <br/><br/>
Further, the Customer shall be solely responsible/liable in keeping his/her credentials confidential to prevent unauthorized access/use of his/her Digital Banking Channels by any third party. For any payment effected by the Bank to a beneficiary based on the information received by the Bank from the Customer’s Digital Banking Channel profile registered in the Bank’s record, the Customer shall be solely responsible/liable in any manner whatsoever for any loss, claim, liability as the case may be or incidental thereto.<br/><br/>
The Customer agrees that the Bank shall not be held liable and shall be absolved from all liabilities whatsoever, if due to any reason beyond the control of the Bank, the Bank is unable to receive or execute any of the requests from the Customer or there is loss of information during the process of transmission or processing. The Customer further agrees that he/ she shall not hold the Bank responsible/ liable for any loss whatsoever incurred to the Customer due to any failure or delay in transmission of information, if there is any error or inaccuracy of information or any other consequence arising from any cause beyond the control of the Bank which may include technology failure, mechanical breakdown, power disruption, error in transmission of information or message to any from the telecommunication equipment of the Customer and the network of any service provider and the Bank’s system or any breakdown, interruption, suspension or failure of the telecommunication equipment of the Customer or the Bank’s system”.<br/>
<br/>
The Bank will not be responsible for any damage, loss or consequential damage which the Customer may suffer as a result of: <br/>
a.	Any malfunction or defect in the hardware used by the Customer (which includes the personal computer or mobile phone).<br/>
b.	Any defect in the software used by the Customer to gain access to the Digital Banking Channels. <br/>
c.	Any act or omission by the Internet or Mobile Network Operator or any defect in the Digital Banking Channels or any other medium by which access is gained to the system. <br/>
d.	The Digital Banking Channels being off-line or unavailable;<br/>
e.	Any industrial action;<br/>
f.	Any other circumstances not reasonably within the Bank’s control;<br/>
g.	Erroneous, unauthorized, incomplete or unlawful instruction from the Customer.<br/>
h.	Unlawful or unauthorized access by another person(s);<br/>
i.	Incorrect or late execution on non-payment of any instruction given by the Customer, due to the circumstances set out in 12 (a to h) above.<br/>
<br/>15. INTELLECTUAL PROPERTY<br/>

The Bank retains all copyright and other intellectual property rights in all material, including logos and other graphics and multimedia works published in the print media or website.<br/><br/>
The customer is authorized to view and download a copy of these Terms and Conditions to a local hard drive or disk, print and make copies of such printouts, provided that the material is used for considering use of the Digital Banking Channels and for no other commercial purposes. <br/><br/>
Any reproduction of the Bank’s proprietary material from the website or portion of it must include the Bank’s copyright notice in its entirety. <br/><br/>
Nothing on the website should be construed as granting any license or right to use any trademark without the Bank’s prior written consent and/or the prior written consent of the third parties, as the case may be. The customer may not without the Bank’s prior written consent, use the Bank’s intellectual property or the intellectual property of third parties for any purposes whatsoever. <br/>
<br/>16. TERMINATION, SUSPENSION OR WITHDRAWAL OF THE DIGITAL BANKING CHANNELS<br/>

The Customer may terminate the Digital Banking Channels by giving the Bank written notice. The notice should be signed by the Customer (s) in accordance with their account mandate. <br/><br/>
The Bank is entitled, at its discretion, to terminate the Digital Banking Channels at any time the Bank deems it necessary. <br/><br/>
In order to protect the Customer, the Bank and the banking systems, the Bank is entitled to immediately suspend or withdraw all or part of the Digital Banking Channels if the Channel is being used contrary to the Terms and Conditions of this agreement; the Bank has reasonable grounds to believe that the channel may be used negligently, illegally or fraudulently or the Bank believes that the security of the system used to provide the Digital Banking Channels may be compromised. <br/><br/>
In the event that the Digital Banking Channels not being used for a period of 6 (six) months or longer, the Bank reserves the right to terminate the agreement without prior notice. The Customer may reapply for the Digital Banking Channels at any time. The customer is liable for all transactions and/or obligations (and related charges) which are incurred up to and including the date of termination.<br/>
<br/>17. GENERAL CONDITIONS<br/>

Transactions initiated through IZB Digital Banking Channels are irrevocable; the Bank shall not entertain any request for revocation of transaction or stop payment request for transactions initiated through IZB Digital Banking Channels.<br/><br/>
Customers shall not use Digital Banking Channels for transactions that are in contradiction to any of the Terms and Conditions or any applied laws and procedures or the regulations of Bank of Zambia and Anti Money Laundering laws and Combating of Terrorist and Proliferation Financing, including for example but not limited to buying any products, goods, or services prohibited according to the Laws of Zambia, or dealing with restricted entities and individuals. The Bank reserves the rights to reject such transactions.<br/><br/>
Customers shall be responsible for the safe custody and security of the IZB Digital Banking Channels application downloaded on their mobile phones/device to avoid unauthorized usage and should immediately inform the Bank for disabling of their Digital Banking Channels profile (s) in case of loss or theft of credentials.<br/><br/>
Customers should NOT share their credentials with anyone including Bank’s staff, associate or representative. <br/><br/>
Customers shall abide by the limits imposed by Bank on maximum number of transactions and/or maximum amount permitted through Digital Banking Channels. The Bank has the right to the limits the Bank sees adequate and is committed to inform the customer with the means it sees adequate. The Bank shall publish the transactions limits for the information of the Customers in a manner/form that the Bank deems adequate.<br/><br/>
In case any information or data related to the Customer is changed, the Customer is committed to inform the Bank right away of the change.<br/><br/>
All information on the Bank’s website is only intended to provide the Customer with general information about the Bank, Bank products and services and the Bank will endeavour to ensure that the information provided is up to date. The Bank will periodically amend, change, add, delete, update or alter the information, including without limitation, the Terms and Conditions of use on its website, without notice. <br/><br/>
Further, Bank assumes no responsibility for any errors or omissions in the content of the website. Users agrees to indemnify, defend and hold the Bank harmless from any liability, loss, claim and expense, including legal fees related to a user’s violation of these Terms and Conditions or unauthorized use of the services and information provided on the website.<br/><br/>
The Terms and Conditions will be governed by and construed in accordance with the applicable laws of the Republic of Zambia and users agree that all legal proceedings relating to the matters herein shall be adjudicated solely in the courts of the Republic of Zambia. To the extent that any portion of these Terms and Conditions shall be determined to be unenforceable by a court of competent jurisdiction, such portion will be modified by the court solely to the extent necessary to cause such portion to be enforceable, and these Terms and Conditions as so modified will remain in full force and effect.<br/><br/>
Any waiver of any provisions contained in these Terms and Conditions by the Bank shall not be deemed to be a waiver of any other right, term or provision of these Terms and Conditions <br/>
<br/>
18. PRIVACY TERMS AND CONDITIONS
<br/><br/>
PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE
<br/><br/>
<strong>These terms tell you the rules for using our website.
</strong>
<br/><br/>
1. Collection of Personal Information <br/>
▪	when you access or use our Digital Banking application, we may collect certain personal information from you. This information may include but is not limited to, your name, email address, phone number, postal address, account details, fingerprints, device location, and contact list. The application will also have access to your device’s camera. <br/>
▪	By using our Digital Banking application, you acknowledge and agree that we may collect and process your personal information as outlined in our Privacy Policy. <br/>
▪	The personal information collected from you will be used solely for the purpose of providing and improving our services, as well as for KYC (know your customer) information. <br/>
▪	We will take reasonable measures to ensure the security and confidentiality of your potential information, and we will not disclose it to any third parties without your explicit consent unless required by law in the Republic of Zambia.<br/>
<br/><br/>

2. Purposes of Collection, Use and Disclosure <br/>
We use the information collected on our Digital Banking application for the purposes listed
in the Purposes section found below.

<br/><br/>
Purposes and Use

<br/><br/>
Your information may be collected, used and disclosed for the following purposes:

<br/><br/>
▪ Verifying your identity (such as when you access your account information)<br/>
▪ Process applications, requests and transactions<br/>
▪Allowing you to send money to beneficiaries having mobile numbers with service
providers such as MTN, Airtel and Zamtel when you allow access to the contacts list
<br/>
▪ Displaying branches, ATMs and agencies near you when your location is used<br/>
▪ Preventing fraud and enhancing the security of your account and online services<br/>
▪ Responding to your requests and communicating with you<br/>
▪Complying with and enforcing legal requirements, relevant industry standards,
contractual obligations and our policies<br/><br/>
The purposes for which your information is collected may be applicable for us to process
your information or for a third-party service provider to process your information in relation
to the Service.<br/><br/>
3. Consent to Data Collection and Processing<br/>
▪ By accepting these terms and conditions, you expressly consent to the collection,
processing, and storage of your personal information as described herein.<br/>
▪ You understand that your personal information may be transferred to and stored on
servers located in different sites within the country. By providing your information, you
consent to such transfers. <br/>
▪ You have the right to withdraw your consent at any time. To do so, please contact us
by using our different communication channels.<br/><br/>

4. Accuracy and Updates <br/>
▪ You are responsible for providing accurate and up-to-date information when using our
Digital Banking application. Please ensure that your personal information is correct and
notify us promptly of any changes. <br/>
▪ We will make reasonable efforts to keep your personal information accurate and upto-date. However, we cannot guarantee the accuracy of the information provided by
you or ensure that it always remains current. <br/><br/>


5. Rights and Access  <br/>
You have the right to request access, correction, or deletion of your Digital Banking
profile held by us. To exercise these rights, please contact us using our different
communication channels. We will respond to your requests in accordance with
applicable data protection laws and within a reasonable timeframe. <br/><br/>








19. COMPLAINTS/CUSTOMER SERVICE<br/>
Please refer your complaints to IZB Digital Banking Unit. When making a complaint you must follow IZB’s published complaint resolution procedure. A copy is available on the Bank’s website, any IZB branch or agencies. <br/><br/>
The contact Details for Digital Banking Unit and Call Center are as below;<br/><br/>
<strong>Digital Banking:</strong><br/>
Phone Numbers: +211 227194/220799<br/>
Email: support_digital@izb.co.zm<br/>
<br/>
<strong>Call Center:</strong><br/>
Toll Free: 8002<br/>
E-Mail: callcenter@izb.co.zm<br/>

            </p>
            
          )}
        </div>
      </li>
</>
    );
}

export default PrivacyAccordion

