export const question = [
  {
    id: 1,
    question: "Eligibility",
    answer: [
      {
        item: "Applicants must be eligible to be categorized under MSME Segment as per Government MSME Policy",
      },
      {
        item: "Applicants must be Formally registered with the Ministry of SMEs, Registrar of Companies or relevant Government body, or Must be willing to be formally registered with the relevant Government body Any other micro units as the bank may deem fit.",
      },
      {
        item: "Applicants who operate in any economic sector; Applicants who have maintained an account with the Bank for a minimum period of 3 months or are able to provide bank statements from another bank",
      },
    ],
  },
  {
    id: 2,
    question: "Purpose",
    answer: [
      {
        item: "For Personal & Business needs.",
      },
    ],
  },
  {
    id: 3,
    question: "Security",
    answer: [
      {
        item: "Working capital limit to be primarily secured by Lien/Assignment/Charge/Hypothecation of Stocks & Receivables.",
      },
      {
        item: "Term Loan to be primarily secured by assets created out of the Bank Finance. ",
      },
    ],
  },
  {
    id: 4,
    question: "Repayment Method",
    answer: [
      {
        item: "Repayment will be as per cash ﬂows of the business unit for the duration of the facility up to a maximum of 60 months subject to review.",
      },
    ],
  },
];
