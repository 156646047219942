import React from 'react'
import { useState,useEffect } from 'react';
import { AiOutlineArrowRight, AiOutlineArrowUp } from 'react-icons/ai';
import { ReactComponent as ArrowR } from "../../assets/images/IZB/arrow_right.svg";



const PrivacyAccordion = ({title,description}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    const [show6, setShow6] = useState(false);


    return (
      <>
      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow(!show)}>
          Definitions
          </h3>
        </div>
        <div className="acc-content current">
          {show && (
            <>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               <strong style={{color:"rgb(158, 27, 30)"}}>Service/Services : </strong>
               These terms and conditions of use become effective when the user accesses this site for the first time and constitute a binding agreement between INDO-ZAMBIA BANK LIMITED and/ or its subsidiaries or affiliates, as the case may be and the user.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
                <ArrowR />
                <strong style={{color:"rgb(158, 27, 30)"}}>Bank : </strong>
                means IZB and all its branches.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
                <ArrowR />
                <strong style={{color:"rgb(158, 27, 30)"}}>Service Provider : </strong>
                Any of the establishments the bank contracts directly or indirectly through third parties to provide some services related to providing the Service e.g., Zesco, MTN, Airtel, Zamtel and DSTV.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
                <ArrowR />
                <strong style={{color:"rgb(158, 27, 30)"}}>Customer : </strong>
                means the actual person subscribed in the Service related to this contract, under the condition that he/she possesses or owns a valid mobile device/ line and valid identity document.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
                <ArrowR />
                <strong style={{color:"rgb(158, 27, 30)"}}>Indo Wallet : </strong>
                means the wallet account opened at the bank in the name of the customer registered with his/her mobile number, the Wallet account is used for depositing, withdrawing, transferring, and other types of transactions.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
                <ArrowR />
                <strong style={{color:"rgb(158, 27, 30)"}}>Electronic Balance : </strong>
                means the credit balance of money in the wallet account, resulting from transactions performed on the wallet account with the knowledge of the customer, this electronic payment is transferable to any other customer and/or service provider, and it is also usable as a payment tool for entities announced by the bank.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
                <ArrowR />
                <strong style={{color:"rgb(158, 27, 30)"}}>IZB : </strong>
                means Indo Zambia Bank Limited.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
                <ArrowR />
                <strong style={{color:"rgb(158, 27, 30)"}}>Mobile : </strong>
                means any mobile device that is compatible with any of the working mobile network operators working in the Republic of Zambia.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
                <ArrowR />
                <strong style={{color:"rgb(158, 27, 30)"}}>Charges : </strong>
                mean the fee payable by the customer in relation to each transaction, as published by the bank from time to time on the digital channels.
            </p>
            </>
          )}
        </div>
      </li>

      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show1 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow1(!show1)}>
          Mechanism of Service Providing
          </h3>
        </div>
        <div className="acc-content current">
          {show1 && (
            <>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               Once the customer signs up, and on the condition that he/she has fulfilled other requirements, and completed Indo Wallet account opening procedures, and after verifying his/her identity by the bank, the bank will send SMS to the customer confirming the wallet account opening and notifying him/her. The customer logs in to his/her wallet to create a password to use the mobile wallet, and should be kept under his/her own responsibility and can change it in case it was exposed.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The bank keeps its right to revise and approve or reject the customer’s onboarding request on the service. The bank keeps its right to verify no false play at any time while the service is up and running.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The wallet has to be linked to a mobile line number provided by any of the active mobile networks in Zambia, and this line has to be owned by the customer and in his/her actual possession.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The customer has the right to deal with his wallet account by depositing or withdrawing amounts whether through the Bank’s Agency Banking and/or available platforms or through transferring amounts from his/her wallet account to other customers and/or service providers and anyone of them can withdraw or deposit it to his/her electronic account within the limits set by the Bank from time to time.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The customer has the right at any time to close his/her wallet and liquidate his/her electronic deposits through calling the Bank and requesting wallet account cancellation.
            </p>
            </>
          )}
        </div>
      </li>

      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show2 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow2(!show2)}>
          Using the Service
          </h3>
        </div>
        <div className="acc-content current">
          {show2 && (
            <>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The right to use the Service or benefiting from it is limited to the customer only and no one else, the customer is committed at all times not to let any other person to use or benefit from it.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The customer has to show in person with an accepted identification when executing withdrawal transactions.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The customer has no right to withdrawal of funds in wallets in the name of other customers.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The bank has the right according to its own assessment to stop or cancel the Service in case used in contradiction to any of the terms and conditions or any applied laws and procedures or the regulations of Bank of Zambia and Anti money laundry laws and Combating of Terrorist Financing, including for example but not limited to buying any products, goods, or services prohibited according to the laws of Zambia, or dealing with restricted entities and individuals.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The customer is committed to keep his password under his/her self-control at all times, and according to this, the customer will be solely responsible for disclosing any data or information related to him/her or any of his/her accounts whether or not this disclosure is deliberate and/or the result of a mistake, shortcoming, or negligence at protecting his/her password.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The customer who decides to stop the mobile phone number linked to his/her mobile wallet account on his mobile phone will lose an integral element of him/her being the user of the service and hence will automatically lose his/her right to use the service through his/her suspended mobile phone number.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The customer is obliged to immediately refer back to the bank in case of closing his/her mobile number linked to the service in order to close the account linked.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The customer acknowledges the Bank's right to take the necessary measures to ensure that the customer has actual possession of the mobile phone number associated with the service. The Bank has the right to suspend the service at its sole discretion in case the Bank discovers that the customer has no possession to the line connected to the Service and this shall be done without the Bank incurring any liability.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The customer admits his/her responsibility of/for all transactions and operations executed on the wallet as long as these transactions and operations have been executed through the mobile number linked to the mobile wallet and using the password, and Bank’s books and records shall act as conclusive evidence of the transactions reflected therein against him/her and anyone else.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The Bank will not be responsible for any losses or damages that affect the customer because of the misuse of the Service or because of any mistake done or any damage that might happen from the customer’s side, or because of the insufficiency of the wallet fund, or any other reason related to the customer, or because of any problems or malfunctioning that might occur on the network or mobile phone line.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The customer acknowledges that the Bank is not responsible for any costs or burdens that may occur as a result of the use of the internet service / mobile phone network and/or any problems, malfunctions or outages related to the use of the said internet service / mobile phone.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The Bank will not be responsible for any entities’ refusal of available balance as a payment method and will not be responsible for goods and/or services that the customer will acquire using this balance. Any complaint from the customer in this issue, he/she will have to resolve it with this entity directly, and the Bank’s role will be limited only in this regard to add any amount he/she might get refunded to the mobile account after the bank has received valid documents issued correctly from this entity.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               In case the customer’s mobile phone has been lost or stolen, and any third parties execute any transactions on his/her account through this mobile phone, the customer will be fully responsible towards the Bank for all results based on this usage and the customer is required to stop the account immediately by notifying the Bank through call center number.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               In case the customer has any complaint related to the wallet, the said customer must contact the IZB call center and the agent at the said call center shall transfer this complaint to the concerned department who shall liaise with the customer in due course.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               All Indo Wallet transactions are inside Zambia only and in local currency.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The customer undertakes not to misuse the Indo Wallet or to allow any third party to misuse the Indo Wallet in unusual/suspicious transactions, or in transactions related to financial/fraudulent crimes, money laundering, terrorist financing, trading in virtual currencies and virtual assets, or gambling. The user also undertakes to notify the Bank and the relevant authorities immediately when he/she suspects that any of the said transactions exist, regardless of whether they occurred or not, and regardless of their values.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The customer shall be liable for all direct/indirect loss if he/she breaches these terms and conditions and/or contributed and/or caused the loss by negligent actions or a failure on his/her part to advise the Bank within a reasonable time about any unauthorized access in the account, or about any suspicious transactions.
            </p>
            </>
          )}
        </div>
      </li>

      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show3 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow3(!show3)}>
          Fees
          </h3>
        </div>
        <div className="acc-content current">
          {show3 && (
            <>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The customer commits to pay the fees related to withdrawal, depositing, transfer, and any other additional Services fees provided through the wallet determined by the Bank according to the banking services tariff announced by the Bank from time to time.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The Bank reserves its right to amend any fees at any time according to its own assessment, and announcing fees in the form the Bank sees fit/adequate. The annunciation is considered a working notification. Using the Service after amendment date is considered an acceptance from the customer’s side with no reservations.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               All instructions by the customer and all operations executed through the wallet are binding on him/her and so are any legal consequences that arise as a result of the same. The Bank commits to activate all operations and execute all instructions from the customer to branch or service provider through any of the electronic media used in providing Services provided the said instructions are lawful.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The customer commits irrevocably to compensate the Bank for any losses, claims, damages or costs and/or expenses that the Bank might be liable for and/or bear as a result of the execution of his/her instructions, including any court and legal fees, among others.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               In case the Bank discovers any actions the customer might perform to invade or break anti money laundry and terrorist financing laws, the Bank has the irrevocable right to suspend all dealing on his/her wallet account until the customer proposes an adequate and accepted justification, all this without waving the Bank’s right to take necessary actions proposed under the laws of Zambia.
            </p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The customer acknowledges and accepts the tariffs, fees, and commissions applied on the Bank’s products and Services as declared in the Bank’s branches, agencies, website and call center. The customer also acknowledges that the Bank has the right to amend the declaration without the customer’s prior consent.
            </p>
            </>
          )}
        </div>
      </li>

      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show4 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow4(!show4)}>
          Biometric
          </h3>
        </div>
        <div className="acc-content current">
          {show4 && (
            <>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />The IZB Touch ID login service is a service where you may use your fingerprint registered on a permitted mobile device in lieu of your IZB online/mobile banking username and password as a security code to confirm your identity to access the Bank’s Services/ mobile banking services.</p>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />The IZB Touch ID login service is provided as part of the Bank’s electronic banking services.</p>

            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               You acknowledge and agree that in order to use the IZB Touch ID login service: <br/>
               - You must be a valid user of our Services/ mobile banking services;<br/>
               - You must install our mobile app using a permitted mobile device;<br/>
               - You will need to activate the Fingerprint ID recognition function on your permitted mobile device and register at least one of your fingerprints to control access to the permitted mobile device;<br/>
               - You will be required to undergo a registration process using your IZB online/mobile banking username and password to choose to use the Fingerprints ID you store on your permitted mobile device for accessing our Services/ mobile banking services; upon the successful registration process, the fingerprints stored on your permitted mobile device will be a security code;<br/>
               - You must ensure that only your Fingerprints ID are stored on your permitted mobile device to access the device and you understand that upon the successful registration of your permitted mobile device, any Fingerprint ID that is stored on your permitted mobile device can be used to access mobile banking including access to your accounts; and<br/>
               - You should ensure the security of the security codes as well as the password or code that you can use to register your Fingerprints ID on the permitted mobile device.
       
        
            </p>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />You may still choose to access the mobile app using your IZB online/mobile banking username and password.</p>

            </>
          )}
        </div>
      </li>

      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show5 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow5(!show5)}>
          General Conditions
          </h3>
        </div>
        <div className="acc-content current">
          {show5 && (
      <>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />The only currency used in providing service is Zambian Kwacha.</p>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />Every mobile number is linked only to one Indo Wallet.</p>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />All information stated by the customer in the application request and/or submitted by him/her shall be taken by the Bank to be true. The customer also agrees that the record of his/her application shall remain the sole property of the Bank at all times including in case of canceling the service.</p>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />The customer shall be the actual and only beneficiary of the mobile line linked to the service and mentioned at the application and under his/her custody. The customer shall also be the actual beneficiary of the Indo Wallet linked to his/her mobile phone.</p>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />The customer fully acknowledges his/her responsibility for the safety and legality of the source of any amounts that are deposited in the wallet or transferred to and from the Wallet and commits to verify that the money does not conflict with the Anti-money laundering and terrorist financing laws and its implementing regulations and decisions.</p>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />The customer takes complete responsibility of providing the Bank with valid and legitimate beneficiary information while initiating fund transfers through the Wallet. The customer commits to using digital channels to send money to known beneficiaries, such as friends and family, and acknowledges the fraud risks associated with transactions to third /unknown parties.</p>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />The customer acknowledges the mechanism of submitting complaints to the Bank through the complaint forms in the bank’s website (www.izb.co.zm), branches or through e-mail or the dedicated call center.</p>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />If the customer would like to close his/her account or terminate their subscription in the Wallet, they acknowledge that they need to call the call center. The customer may also inquire about the fees and discounts applied by the Bank in case of closure, and that the Bank has no further responsibilities or obligations in this case.</p>
            <p style={{marginBottom:"0.5rem"}}>
               <ArrowR />
               The Bank is committed to maintain the confidentiality of all data and information that might be handed over by the customer. The Bank is also committed to preserving the confidentiality of all operations performed by the customer through any of the electronic media used in providing the Service. The Bank is committed not to disclose any of the above except; <br/>
               - where the customer has given us his/her consent.<br/>
               - the law requires us to do so.<br/>
               - to detect, prevent and report theft, fraud, money laundering and other crimes.<br/>
               - it is in the public interest to do so.<br/>
               - our interests require disclosure, for example default or breach of this agreement.<br/>
               - to manage our relationship with you<br/>
               - our internal marketing and product development require the information.<br/>
               - to process payment instructions.<br/>
            </p>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />The Bank has the right to record phone calls received on the numbers dedicated to call center and to maintain a record of these calls.</p>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />The Bank at any time has the right to terminate the Service, on the condition of notifying the customer at least 30 days before the termination.</p>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />The Bank has the right to amend any terms and/ or conditions of using the Service, and any amendment will be applicable to the customer once notified through any of the means seen adequate by the Bank as long as the change has been notified, and in case the customer does not accept any of these amends or changes he/she must inform the Bank of his/her wish to terminate contract/ his or her use of the Service within 30 days from the date of receiving notification, and he/ she must stop right away execution of any transactions and to submit to the Bank a request to close the Wallet and execute needed settlements including payment of any dues and refunding any remaining amounts owed to him/her in his/her balance and then follow same procedures of cancellation. The Bank may also transfer any balances in a dormant Indo Wallet account to the Bank of Zambia without referring to the customer, if the account balance has passed a specified period according to Bank of Zambia instructions regarding the dormant accounts.</p>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />In case any information or data related to the customer is changed, the customer is committed to inform the Bank right away of the change.</p>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />The Bank has the right to change the maximum limit of withdrawals or payments or any other transactions as the Bank sees adequate and is committed to inform the customer with the means it sees adequate.</p>
            <p style={{marginBottom:"0.5rem"}}><ArrowR />Overall the Bank is absolved of all and/or any liability, damage or loss that may arise as a result of the use of the Services by the customer and this includes but is not limited to all and/or any liability, damage or loss that incurred by the customer and/or any third parties.</p>

      </>
            
          )}
        </div>
      </li>

      <li className="accordion block active-block">
        <div
          className="acc-btn active"
          style={{ backgroundColor: "#9E1B1E", backgroundSize: "20px" }}
        >
          <div className="icon-outer">
            {show6 ? (
              <AiOutlineArrowRight className="text-white" />
            ) : (
              <AiOutlineArrowUp className="text-white" />
            )}
          </div>
          <h3 className="text-white" style={{textAlign:"left"}} onClick={() => setShow6(!show6)}>
          Complaints/Customer Service
          </h3>
        </div>
        <div className="acc-content current">
          {show6 && (
         <>
         <p style={{marginBottom:"0.5rem"}}>Please refer your complaints to IZB Digital Banking Unit. When making a complaint you must follow IZB’s published complaint resolution procedure. A copy is available on from any IZB branch and agencies. The contact Details for Digital Banking Unit and call centre are as below;</p>
           <p>
               <strong style={{color:"rgb(158, 27, 30)"}}>Digital Banking: </strong>
            </p>
            <p>Phone Numbers: +211 227194/220799</p>
            <p style={{marginBottom:"0.5rem"}}>Email: support_digital@izb.co.zm</p>

            <p>
               <strong style={{color:"rgb(158, 27, 30)"}}>Call Center: </strong>
            </p>
            <p>Toll Free: 8002</p>
            <p>E-Mail: callcenter@izb.co.zm</p>

         </>
            
          )}
        </div>
      </li>




</>
    );
}

export default PrivacyAccordion

