import React, { useEffect } from 'react'
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb'
import AllAcountsSection from '../../Components/AllAccountsSection/AllAcountsSection'
import SavePlusAccountComponent from "../../Components/SavePlusAccount/SavePlusAccountComponent";
// import Arrow_down from "../../assets/images/IZB/arrow_down.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import AgroFinanceAccordion from './AgroFinanceAccordion';
import {question} from "./AgroFinanceData"

const AgroFinance = () => {
const Arrow_down = "https://izbassetsmain.blob.core.windows.net/izbwebassets/arrow_down.png";
const img1 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/agro-finance-2.png";
const BackgroundBrundcrumb = "https://izbassetsmain.blob.core.windows.net/izbwebassets/agro-finance-banner.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const accountContent = "We understand the need for food security and the importance agriculture plays into the country’s national development goals. At Indo Zambia Bank we study, examine and analyse the ﬁnancial aspects pertaining to farm business. Tailor made Agro ﬁnancing product is available making us your all WEATHER Agro Partner of choice."
  return (
    <>
     <Breadcrumb Heading="Agro Finance" img={BackgroundBrundcrumb}  show={true} btnTxt="Apply Now"/>
      <SavePlusAccountComponent
        heading="Agro Finance"
        subHeading="Know About"
        content={accountContent}
        btnTxt="Apply Now"
        img={img1}
        logo={true}
      />

      <section
        className="page-contains-area"
        style={{ backgroundColor: "#FFF5E7" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-contains-box">
                <div className="page-contains-btn">
                  <ul className="navigation clearfix scroll-nav">
                    <li className="current">
                      <a href="#benefits">
                        <img src={Arrow_down} className="me-1" />
                        Eligibility
                      </a>
                    </li>
                  
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="MSMEAccodion">
        <div className="container justify-content-center mt-5">
          <div className="faq-style1__content">
            <ul className="accordion-box mb-5">
              {question.map((item) => {
                const { id } = item;
                return <AgroFinanceAccordion key={id} {...item} />;
              })}
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div
          className="bg-light my-3"
          style={{
            padding: "20px 0px 20px 10px",
          }}
        >
          <h6 style={{ color: "#9E1B1E", textAlign:"left" }}>All other standard lending terms of the Bank shall be applicable.</h6>
        </div>
      </div>
      <AllAcountsSection/>
    </>
  )
}

export default AgroFinance