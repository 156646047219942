import React,{useEffect} from "react";
import img1 from "../../assets/images/IZB/IZB Website Skin/Board_of_Directors/1.png";
import data from "../../Data/BoardOfDirector.json";
// import BackgroundBrundcrumb from "../../assets/images/IZB/BackgroundBrundcrumb.png";
import { Link } from "react-router-dom";
import "../Hero/Hero.css";
const Directors = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const BackgroundBrundcrumb = "https://izbassetsmain.blob.core.windows.net/izbwebassets/BackgroundBrundcrumb.png";

  const before = data[1];
  const after = data[2];
  console.log(data);
  return (
   <section className="executive-director-sec">




      {after.map((item) => {
        return (
          <div className="container">
            <div className="row border justify-content-sm-center p-4 my-5">
              <div className="col-12 col-md-3 col-xl-3">
                <img src={item.Image} className="border" alt="" />
              </div>
              <div className="col-12 col-md-9 col-xl-9">
                <h3 className="h33">{item.Name}</h3>
                <div
                  className="text col-12 col-md-4 col-lg-4 col-xl-4  text-white ps-1 mb-4 mt-2"
                  
                >
                  {item.Designation}
                </div>
                <p>{item.description}</p>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default Directors;
