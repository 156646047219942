import React, { useState, useEffect } from "react";
import HeroBackground from "../../assets/images/IZB/IZB Website Skin/Home_Page/New_changes/Untitled-1.png";
import MobileStoreButton from "react-mobile-store-button";
import "./Hero.css";
import { FiCheckCircle } from "react-icons/fi";
import { Link } from "react-router-dom";

import BankingMenu from "./BankingMenu";

const HeroSlide3 = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setIsMobileView(window.innerWidth < 700); // Set the mobile view based on the window width
    };

    // Add event listener to handle window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const bannerPhones =
    "https://izbassetsmain.blob.core.windows.net/izbwebassets/Group 1000003036.png";
  const fastserve =
    "https://izbassetsmain.blob.core.windows.net/izbwebassets/indo-fast-serve_png.png";
  const Logo = "https://izbassetsmain.blob.core.windows.net/izbwebassets/logo.png";

  const HeroBgimg = {
    // backgroundImage: `url(${HeroBackground})`,
    height: "75vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
  };

  return (
    <>
    
       
          <div style={HeroBgimg} className="HeroSlider4">
            <div className="auto-container sliderContent h-100">
              <div className="h-75 position-relative ">
                <div className="d-flex justify-content-end align-items-center h-100">
                  <div className="flex-column flex-wrap align-self-xl-center text-center">
                    <h5 className="py-1 text-dark">
                      {/* PROGRAMME for the <br /> OFFICIAL OPENING CEREMONY of the */}
                    </h5>
                    <div className="my-3 text-center">
                      
                    </div>
                    <h5 className="py-1 text-dark">
                      {/* NEW CORPORATE HEAD OFFICE */}
                    </h5>
                    <h5 className="py-1 text-dark my-3">
                      {/* GUEST OF HONOUR -{" "} */}
                      <span
                        style={{
                          color: "rgb(158, 27, 30)",
                          fontWeight: "800",
                        }}
                      >
                        {/* MR. HAKAINDE HICHILEMA, */}
                      </span>
                      <br />
                      <span style={{ color: "rgb(158, 27, 30)" }}>
                        {/* PRESIDENT OF THE REPUBLIC OF ZAMBIA */}
                      </span>
                    </h5>
             
                  </div>
                </div>
              </div>
            </div>
          </div>
  
    
    </>
  );
};

export default HeroSlide3;
