import React from "react";
import { useEffect } from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";
import { newData } from "./PrivacyData";
import PrivacyAccordion from "./PrivacyAccordion";
import { ReactComponent as ArrowR } from "../../assets/images/IZB/arrow_right.svg";

// import BackgroundBrundcrumb from "../../assets/images/IZB/BackgroundBrundcrumb.png";

const PrivacyPolicy = () => {
const BackgroundBrundcrumb = "https://izbassetsmain.blob.core.windows.net/izbwebassets/BackgroundBrundcrumb.png";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb Heading="Digital Banking Privacy Policy" img={BackgroundBrundcrumb} />
      <div className="container mt-5">
        <div className="faq-style1__content">
       <h3>PRIVACY POLICY</h3><br/>
       <h4>1. Collection of Personal Information</h4>
       <ul>
        <li>▪ when you access or use our Digital Banking application, we may collect certain personal
information from you. This information may include but is not limited to, your name, email
address, phone number, postal address, account details, fingerprints, device location, and
contact list. The application will also have access to your device’s camera.</li>
        <li>▪ By using our Digital Banking application, you acknowledge and agree that we may collect and
process your personal information as outlined in our Privacy Policy.</li>
        <li>▪ The personal information collected from you will be used solely for the purpose of providing
and improving our services, as well as for KYC (know your customer) information.</li>
        <li>▪ We will take reasonable measures to ensure the security and confidentiality of your potential
information, and we will not disclose it to any third parties without your explicit consent unless
required by law in the Republic of Zambia.</li>

       </ul>
       <br/>
       <h4>2. Purposes of Collection, Use and Disclosure</h4>
       <p>We use the information collected on our Digital Banking application for the purposes listed in the
Purposes section found below.</p><br/>
<h4>Purposes and Use</h4><br/>
<p>Your information may be collected, used and disclosed for the following purposes:</p><br/>
<ul>
  <li>▪ Verifying your identity (such as when you access your account information)</li>
  <li>▪ Process applications, requests and transactions</li>
  <li>▪ The contacts list is accessed for the purpose and use of allowing you to send money to
beneficiaries having mobile numbers with service providers such as MTN, Airtel and Zamtel
during Bank to Wallet/MNO transfers.</li>
  <li>▪ Displaying branches, ATMs and agencies near you when your location is used</li>
  <li>▪ Preventing fraud and enhancing the security of your account and online services</li>
  <li>▪ Responding to your requests and communicating with you</li>
  <li>▪ Complying with and enforcing legal requirements, relevant industry standards, contractual
obligations and our policies</li>

</ul>
<br/>
<p>The purposes for which your information is collected may be applicable for us to process your
information or for a third-party service provider to process your information in relation to the
Service.</p><br/>
<h4>3. Consent to Data Collection and Processing</h4>
<ul>
  <li>▪ By accepting these terms and conditions, you expressly consent to the collection, processing,
and storage of your personal information as described herein.</li>
  <li>▪ You understand that your personal information may be transferred to and stored on servers
located in different sites within the country. By providing your information, you consent to such
transfers.</li>
  <li>▪ You have the right to withdraw your consent at any time. To do so, please contact us by using
our different communication channels.</li>

</ul><br/>
<h4>4. Accuracy and Updates</h4>
<ul>
  <li>▪ You are responsible for providing accurate and up-to-date information when using our Digital
Banking application. Please ensure that your personal information is correct and notify us
promptly of any changes.</li>
  <li>▪ We will make reasonable efforts to keep your personal information accurate and upto-date.
However, we cannot guarantee the accuracy of the information provided by you or ensure that it
always remains current.</li>

</ul><br/>
<h4>5. Rights and Access</h4>
<p>You have the right to request access, correction, or deletion of your Digital Banking profile held
by us. To exercise these rights, please contact us using our different communication channels.
We will respond to your requests in accordance with applicable data protection laws and within
a reasonable timeframe.</p>
        </div>
      </div>
      <br/>
      <div className="col-xl-12 justify-content-center"></div>
      <AllAcountsSection />
    </>
  );
};

export default PrivacyPolicy;
