import React from "react";
import { useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import styles from "./Thankyou.module.css";

const Thankyou = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
    
     <Helmet>
     <script>
    {`
      gtag('event', 'manual_conversion_SUBMIT_LEAD_FORM', {
        // <event_parameters>
      });
    `}
  </script>
      </Helmet>
      {/* <div className="my-5 mx-5 text-center"> */}
      <div className={styles.thankyou}>
      <Link to="/" className="text-white">
      <img
               className={styles.thankyou1}
                  alt=""
                  src="https://izbassetsmain.blob.core.windows.net/izbwebassets/Group-10000.png"
                />
                </Link>
      </div>
    </>
  );
};

export default Thankyou;
