import React from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";
import MobileStoreButton from "react-mobile-store-button";
import AdvisorsVideo from "../../Components/AdvisorsVideo/AdvisorsVideo";
import AllAccounts from "../../Components/AllAccounts/AllAccounts";
import ContactForm from "../../Components/ContactForm/ContactForm";
import CustomerCare from "../../Components/CustomerCare/CustomerCare";
import OurStores from "../../Components/OurStories/OurStores";
import { useEffect } from "react";
import EEZYTable from "../../Components/ServiceTable/EEZYTable";
import { BsCheckCircle } from "react-icons/bs";
import { Link } from "react-router-dom";

const EEZY = () => {
  const img = "https://izbassetsmain.blob.core.windows.net/izbwebassets/eezy2.png";
  const Logo = "https://izbassetsmain.blob.core.windows.net/izbwebassets/Group 402.png";
const Logo1 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/Group 403.png";
const BackgroundBrundcrumb = "https://izbassetsmain.blob.core.windows.net/izbwebassets/izb-cards-banner.png";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const iosUrl =
    "https://apps.apple.com/us/app/izb-retail-banking/id1507943733";
  const androidUrl =
    "https://play.google.com/store/apps/details?id=com.izb&hl=en&gl=US";
  const content =
    "ACCOUNT OPENING MADE EASY! Open an Account on your phone today!";
  return (
    <>
      <Breadcrumb Heading="Indo EEZY" img={BackgroundBrundcrumb} />
      <section className="overview-area">
        <div className="container">
          <div className="row">
            <div className=" col-md-5 col-xl-5">
              <div className="overview-content-box-one">
                <img src={img} width="100%" alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-md-6 ">
              <div className="overview-content-box-two h-100 d-flex flex-column justify-content-center">
                <div className="inner-title">
                  <h5>Know About</h5>
                  <h2>Indo EEZY</h2>
                </div>
                <div className="text">
                  <p>{content}</p>
                </div>
                <div className="d-flex align-items-center">
                <Link to={androidUrl} target="_blank" >
                    <img src={Logo} width={150} style={{textDecoration:"none", border:"none"}} />
                  </Link>
                    <Link to={iosUrl} target="_blank">
                    <img src={Logo1} width={150} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-5">
          <div
            className="row gy-2 justify-content-around"
            style={{ gap: "3.5rem" }}
          >
            <div className="heading text-center">
              <h1 style={{ color: "#484848" }}>
                Simple Steps to Open an Account
              </h1>
              <p style={{ color: "#767676" }}>
                We help business and cutomers achieve more.
              </p>
            </div>
            <div
              className="col-md-2 text-wrap text-center text-white"
              style={{ backgroundColor: "#9E1B1E", height: "12em" }}
            >
              <h5 className="text-white pt-5">Step 1</h5>
              <p className="text-center" style={{fontSize:"20px"}}>Download the Indo Zambia Bank App.</p>
            </div>
            <div
              className="col-md-2 text-wrap text-center text-white"
              style={{ backgroundColor: "#9E1B1E", height: "12em" }}
            >
              <h5 className="text-white pt-5">Step 2</h5>
              <p className="text-center" style={{fontSize:"20px"}}>
                Register as a new customer or existing customer.
              </p>
            </div>
            <div
              className="col-md-2 text-wrap text-center text-white"
              style={{ backgroundColor: "#9E1B1E", height: "12em" }}
            >
              <h5 className="text-white pt-5">Step 3</h5>
              <p className="text-center" style={{fontSize:"20px"}}>
                Submit details and wait whilst your account is being activated.
              </p>
            </div>
            <div
              className="col-md-2 text-wrap text-center text-white"
              style={{ backgroundColor: "#9E1B1E", height: "12em" }}
            >
              <h5 className="text-white pt-5">Step 4</h5>
              <p className="text-center pb-3" style={{fontSize:"20px"}}>Account Opened</p>
              <BsCheckCircle size={60} />
            </div>
          </div>
        </div>
      </section>
      {/* <EEZYTable title="Indo EEZY Service Charges" /> */}
      <AdvisorsVideo />
      <AllAccounts />
      <ContactForm />
      <CustomerCare />
      <OurStores />
    </>
  );
};

export default EEZY;
