import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const VideoSlider = () => {
  const [sliderRef, setSliderRef] = useState(null);
  const [hoveredVideo, setHoveredVideo] = useState(null);

  const videoData = [
    { id: 1, videoSrc: 'https://www.youtube.com/embed/kOLGe0PJnXU' },
    { id: 2, videoSrc: 'https://www.youtube.com/embed/AGaHvmSTUVk' },
    { id: 3, videoSrc: 'https://www.youtube.com/embed/UbiXtoZN1Js' },
    { id: 4, videoSrc: 'https://www.youtube.com/embed/bKTvnoOplh4' },
    { id: 5, videoSrc: 'https://www.youtube.com/embed/rNCncIiTgSI' },
    { id: 6, videoSrc: 'https://www.youtube.com/embed/_O2aqt9qtTA' },
  ];

  useEffect(() => {
    document.body.style.backgroundColor = '#FFF5E7';
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleArrowClick = (direction) => {
    if (sliderRef) {
      direction === 'left' ? sliderRef.slickPrev() : sliderRef.slickNext();
    }
  };

  return (
    <div className='video-section' style={{ paddingTop: '30px', paddingBottom: '30px', overflow: 'hidden !important', paddingLeft:'30px', paddingRight: '30px' }}>
      <div className='text-center my-5'>
        <h1 style={{ color: "#484848" }}>Unlock Seamless Banking Convenience</h1>
        <p style={{ color: "#484848" }}>
          Explore Our Indo Mobile Banking Tutorial Videos
        </p>
      </div>
      <Slider ref={(slider) => setSliderRef(slider)} {...settings}>
        {videoData.map((item) => (
          <div key={item.id} className="slide" style={{ padding: '10px', textAlign: 'center' }}>
            <div
              className="video-wrapper"
              style={{ position: 'relative', minHeight: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 10px' }}
              onMouseEnter={() => setHoveredVideo(item.id)}
              onMouseLeave={() => setHoveredVideo(null)}
            >
              <iframe
                width="100%"
                height="400px"
                src={`${item.videoSrc}${hoveredVideo === item.id ? '?autoplay=1' : ''}`}
                title={`YouTube video ${item.id}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ maxWidth: '800px', borderRadius: '10px' }}
              ></iframe>
            </div>
          </div>
        ))}
      </Slider>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <button onClick={() => handleArrowClick('left')} style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '0 20px' }}>
          <img src="https://izbassetsmain.blob.core.windows.net/izbwebassets/LeftArrow.png" alt="Left Arrow" style={{ width: '50px', height: '50px' }} />
        </button>
        <button onClick={() => handleArrowClick('right')} style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '0 20px' }}>
          <img src="https://izbassetsmain.blob.core.windows.net/izbwebassets/RightArrow.png" alt="Right Arrow" style={{ width: '50px', height: '50px' }} />
        </button>
      </div>
    </div>
  );
};

export default VideoSlider;
