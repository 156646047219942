import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AdvisorsVideo from "../../Components/AdvisorsVideo/AdvisorsVideo";
import AllAccounts from "../../Components/AllAccounts/AllAccounts";
import ContactForm from "../../Components/ContactForm/ContactForm";
import CustomerCare from "../../Components/CustomerCare/CustomerCare";
import OurStores from "../../Components/OurStories/OurStores";
import axios from 'axios';

const ForexRates = () => {
  const BackgroundBrundcrumb = "https://izbassetsmain.blob.core.windows.net/izbwebassets/Forex_Savings_Banner.png";
  const [rates, setRates] = useState([]);
  const [updateTime, setUpdateTime] = useState(null); // New state for timestamp

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Function to fetch forex rates
    const fetchRates = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/forex/rates`);
        if (response.data && response.data.rates) {
          setRates(response.data.rates); // Assuming response.data.rates is an array
          
          // Set the timestamp (if present)
          if (response.data.timestamp) {
            const formattedTime = new Date(response.data.timestamp).toLocaleString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              timeZone: 'Africa/Lusaka',
            });
            setUpdateTime(formattedTime);
          }
        }
      } catch (error) {
        console.error('Error fetching forex rates:', error);
      }
    };

    // Fetch rates on component mount
    fetchRates();

    // Fetch rates every 1 hr
    const intervalId = setInterval(() => {
      fetchRates();
    }, 3600000);

    // Cleanup intervals on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <Breadcrumb
        Heading="Forex Rates"
        img={BackgroundBrundcrumb}
        show={true}
        btnTxt="Open an Account"
      />
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div className="my-5" style={{ textAlign: "center" }}>
          <h2 className="my-5 mx-3" style={{ border: "1px solid", padding: "2vw", color: "white", background: "rgb(158, 27, 30)" }}>Indicative Forex Rates</h2>
          
          {/* Displaying the update time here */}
          {updateTime && (
            <h4 className="my-3 mx-3" style={{ color: "rgb(72, 72, 72)" }}>
              <b>Last Updated :</b> {updateTime}
            </h4>
          )}
          

          <table className="my-3 forexRateTable px-1" style={{ margin: "0 auto", textAlign: "center", borderCollapse: "collapse", border: "1px solid black" }}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black", padding: "1vw 3vw", background: "rgb(158, 27, 30)", color: "white" }}></th>
                <th style={{ border: "1px solid black", padding: "1vw 3vw", background: "rgb(158, 27, 30)", color: "white" }}>Buying (ZMW)</th>
                <th style={{ border: "1px solid black", padding: "1vw 3vw", background: "rgb(158, 27, 30)", color: "white" }}>Selling (ZMW)</th>
              </tr>
            </thead>
            <tbody>
              {rates && rates.map(({ currencyPair, buyingRate, sellingRate }) => (
                <tr key={currencyPair}>
                  <td style={{ border: "1px solid black", padding: "1vw 3vw", fontWeight: "500", color: "rgb(158, 27, 30)" }}>{currencyPair}</td>
                  <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>{buyingRate}</td>
                  <td style={{ border: "1px solid black", padding: "1vw 3vw" }}>{sellingRate}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <br />

          <h6 className="my-5 mx-3" style={{ border: "1px solid", padding: "2vw", color: "white", background: "rgb(158, 27, 30)" }}>
            The above rates are indicative and subject to change without notice. <br /> 
            For more details on Forex rates or any queries: <br /> <br/>
            Please call 
            <a href="tel:260211228245" style={{ color: "#fff", fontWeight: "600" }}>+260 211 228245</a> / 
            <a href="tel:260211238924" style={{ color: "#fff", fontWeight: "600" }}>+260 211 238924</a> <br />
            Customer Support Available: 24/7
          </h6>

        </div>
      </div>

      <AdvisorsVideo />
      <AllAccounts />
      <ContactForm />
      <CustomerCare />
      <OurStores />
    </>
  );
};

export default ForexRates;
