import React from "react";

import "../Hero/Hero.css";

const CustomerCare = () => {
  const CustomerImg = "https://izbassetsmain.blob.core.windows.net/izbwebassets/Image_3.png";
const CustomerCareImg = "https://izbassetsmain.blob.core.windows.net/izbwebassets/Rectangle 9.png";
const Phone = "https://izbassetsmain.blob.core.windows.net/izbwebassets/PhoneCall.png";
const Call = "https://izbassetsmain.blob.core.windows.net/izbwebassets/phone 1.png";
const whatsapp = "https://izbassetsmain.blob.core.windows.net/izbwebassets/whatsapp 1.png";
const mail = "https://izbassetsmain.blob.core.windows.net/izbwebassets/mail 1.png";
  const CustomerBgImg = {
    backgroundImage: `url(${CustomerImg})`,
    // height: "70vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
  };
  const containerFluid = {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
  };
  return (
    <section className="overview-area customerSection">

   
    <div className="container-fluid" style={{ containerFluid }}>
      <div className="row align-items-center" style={CustomerBgImg}>
        <div className="container">
          <div className="row">
            <div className="col-md-5 py-5 px-0">
              <img src={CustomerCareImg} className="p-0" alt="" />
            </div>
            <div className="col-md-6 py-5">
              <h2 className="text-white">Reach us When you need us</h2>
              <a className=" mx-2 my-2 rounded d-flex align-items-center">
                <div className="icon ">
                  <img src={Phone} width={40} className="style2 text" alt="" />
                </div>
                <div className="text ">
                  <h3 className="text-white mx-3">Toll-Free</h3>
                </div>
                <div className="text">
                  <h4
                    className="p-2 rounded"
                    style={{ backgroundColor: "#FFC975", color: "#9E1B1E" }}
                  >
                    8002
                  </h4>
                </div>
              </a>
              <p className="text-white my-3" style={{ lineHeight: "1.3" }}>
                What can Indo Zambia Bank do to better serve your needs? <br />
                Click the link Below for our Agents to serve you online.
              </p>
              <div className="h-100">
                <div className="text d-flex">
                  <h6
                    className="p-2 rounded"
                    style={{
                      backgroundColor: "#FFC975",
                      color: "#9E1B1E",
                      width: "350px",
                    }}
                  >
                    <img
                      src={Call}
                      width={22}
                      style={{ marginRight: "10px" }}
                      alt=""
                    />
                    +260 2113 89900
                  </h6>
                </div>
                <div className="text d-flex">
                  <h6
                    className="p-2 my-2 rounded"
                    style={{
                      backgroundColor: "#FFC975",
                      color: "#9E1B1E",
                      width: "350px",
                    }}
                  >
                    <img
                      src={whatsapp}
                      width={22}
                      style={{ marginRight: "10px" }}
                      alt=""
                    />
                    +260 7717 98616{" "}
                  </h6>
                </div>
                <div className="text d-flex">
                  <h6
                    className="p-2 rounded"
                    style={{
                      backgroundColor: "#FFC975",
                      color: "#9E1B1E",
                      width: "350px",
                    }}
                  >
                    <img
                      src={mail}
                      width={22}
                      style={{ marginRight: "10px" }}
                      alt=""
                    />
                    customerservice@izb.co.zm
                  </h6>
                </div>
             
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

export default CustomerCare;
