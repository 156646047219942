import React from "react";

import ContactForm from "../../Components/ContactForm/ContactForm";
import CustomerCare from "../../Components/CustomerCare/CustomerCare";
import OurStores from "../../Components/OurStories/OurStores";
// import EmailAlert from "../../assets/images/IZB/IZB Website Skin/IZB Save Plus Account/Icons/alert.png";
// import atm from "../../assets/images/IZB/IZB Website Skin/IZB Save Plus Account/Icons/atm.png";
// import file from "../../assets/images/IZB/IZB Website Skin/IZB Classic Savings Account/Icons/file.png";
import { AiFillCaretRight } from "react-icons/ai";
import { useEffect } from "react";
import ClassicSavingsTable from "../../Components/ServiceTable/ClassicSavingsAccTable";

const ContactForm1 = () => {



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>

      <ContactForm />
      <CustomerCare />
      <OurStores />
    </>
  );
};

export default ContactForm1;
