import React from 'react'
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb'
import AllAcountsSection from '../../Components/AllAccountsSection/AllAcountsSection'
import Directors1 from '../../Components/Directors/Directors1'


// import BackgroundBrundcrumb from "../../assets/images/IZB/BackgroundBrundcrumb.png";
import { useEffect } from 'react';

const BoardOfDirectors = () => {
  const BackgroundBrundcrumb = "https://izbassetsmain.blob.core.windows.net/izbwebassets/BackgroundBrundcrumb.png";
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    
    <>
    <Breadcrumb Heading="Executive Management" img={BackgroundBrundcrumb}/>
    <Directors1/>
  
    
    <AllAcountsSection/>
    </>
  )
}

export default BoardOfDirectors