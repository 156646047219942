import React from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";
import { Link } from "react-router-dom";

import MobileStoreButton from "react-mobile-store-button";
import ContainsArea from "../../Components/ContainsArea/ContainsArea";

import bgimg from "../../assets/images/IZB/IZB Website Skin/IZB Save Plus Account/image_6.png";
import { AiFillCaretRight, AiOutlineRight } from "react-icons/ai";
import { useEffect } from "react";


const MobileBanking = () => {
  const img1 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/bill 3.png";
const img4 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/money-transfer.png";
const img5 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/indo-mobile-banking-money.png";
const img6 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/online-banking.png";
const bannerPhones = "https://izbassetsmain.blob.core.windows.net/izbwebassets/Group 1000003036.png";
const BackgroundBrundcrumb = "https://izbassetsmain.blob.core.windows.net/izbwebassets/Rectangle 2.png";
const img = "https://izbassetsmain.blob.core.windows.net/izbwebassets/wallet-Rectangle 5.png";
const Logo = "https://izbassetsmain.blob.core.windows.net/izbwebassets/Group 402.png";
const Logo1 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/Group 403.png";
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const textColor = {
    color: "#9E1B1E",
  };
  const iosUrl =
    "https://apps.apple.com/us/app/izb-retail-banking/id1507943733";
  const androidUrl =
    "https://play.google.com/store/apps/details?id=com.izb&hl=en&gl=US";
  const content =
    "Indo Wallet Banking is a cutting-edge digital solution tailored for seamless financial transactions. Its intuitive interface facilitates effortless funds transfers and bank to mobile money transfers. You can enjoy the convenience of purchasing Air Time, Data Top Ups, and a range of bill payments (ZESCO, DSTV, GOTV, TOPSTAR). Setting up your IZB Indo Wallet is hassle free thanks to its user-friendly self-onboarding feature, accessible through USSD by dialing *232#.";
  return (
    <>
      <Breadcrumb Heading="Indo Wallet Banking" img={BackgroundBrundcrumb} />
      <section className="overview-area">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-xl-5">
              <div className="overview-content-box-one">
                <img src={img} width="100%" alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-md-6 ">
              <div className="overview-content-box-two h-100 d-flex flex-column justify-content-center">
                <div className="inner-title">
                  <h5>Know About</h5>
                  <h2>IZB Indo Wallet Banking</h2>
                </div>
                <div className="text">
                  <p>{content}</p>
                </div>
                <div className=" align-items-center">
                <Link to={androidUrl} target="_blank" >
                    <img src={Logo} width={150} style={{textDecoration:"none", border:"none"}} />
                  </Link>
                    <Link to={iosUrl} target="_blank">
                    <img src={Logo1} width={150} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <ContainsArea /> */}

      <section
        id="benefits"
        class="benefits-style2-area"
        className="choose-style1-area"
        style={{padding:"0px"}}
      >
        <div className="container pt-4">
          <div className="text-center my-5">
            <h1 style={{ color: "#484848" }}>IZB INDO Wallet Banking Benefits</h1>
            <p style={{ color: "#484848" }}>
              We help Businesses and customers achieve more.
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <ul className="row choose-style1__content">
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={img4} alt="" />
                  </div>
                  <div className="text">
                    <h5>Money Transfers</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={img6} alt="" />
                  </div>
                  <div className="text">
                    <h5>Bank To wallet transfers</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={img5} alt="" />
                  </div>
                  <div className="text">
                    <h5>Purchase Airtime and Data Top Ups</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={img1} alt="" />
                  </div>
                  <div className="text">
                    <h5>Bill Payments(ZESCO, DSTV, GOTV,TOPSTAR)</h5>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
<hr className="container" style={{color:"#9E1B1E", border:"1px solid #9E1B1E"}}/>


<Link to="/TermsandCondition">
<p className="container" style={{color:"#9E1B1E", marginBottom:"2vw"}}><strong>Click here for Terms & Conditions</strong></p>
</Link>
      <AllAcountsSection />
    </>
  );
};

export default MobileBanking;
