


import React,{useRef} from "react";
import { useEffect } from "react";
import Breadcrumb1 from "../../Components/Breadcrumb1/Breadcrumb1";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";


// import BackgroundBrundcrumb from "../../assets/images/IZB/BackgroundBrundcrumb.png";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineShareAlt,
} from "react-icons/ai";

import { Link } from "react-router-dom";
import { CiClock1 } from "react-icons/ci";
import { BiMessageDetail } from "react-icons/bi";
const Invitationn = () => {
const BackgroundBrundcrumb = "https://izbassetsmain.blob.core.windows.net/izbwebassets/BackgroundBrundcrumb.png";
const storie2 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/12.png";
const storie5 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/14.png";

const arroww = "https://izbassetsmain.blob.core.windows.net/izbwebassets/chevron_right.svg";

const storie21 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/q3twge.jpg";

const arrowwreport1 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/Thumbnailnew_Image.png";
const arrowwreport2 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/IZB_TENDER_Cancelled (1)_page-0001.jpg";
  const sliderRef = useRef(null);
  console.log(sliderRef.current);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>


    
<Breadcrumb1 Heading="Tender For Solar Hybrid Inverter And Battery System Across IZB Branch Network" Heading1 ="Indo Zambia Bank  |  July, 2024" img={BackgroundBrundcrumb} />

<div className="text-center" style={{ margin: "3rem" }}>
  <div className="">
  <div> <img src={arrowwreport2} className="img-fluid image-row-report" alt="Image 1" style={{ flex: "1", order: "1",margin: "0 0.5rem"}} /></div>
 
    <div> <img src={arrowwreport1} className="img-fluid" alt="Image 2" style={{ flex: "1", order: "2", margin: "0 0.5rem" }} /></div>
   {/* <div> <img src={arrowwreport2} className="img-fluid" alt="Image 2" style={{ flex: "1", order: "2",margin: "0 0.5rem"}} /></div>
   
    <div><img src={arrowwreport3} className="img-fluid" alt="Image 3" style={{ flex: "1", order: "3",margin: "0 0.5rem" }} /></div>
    <div><img src={arrowwreport4} className="img-fluid" alt="Image 4" style={{ flex: "1",order: "4", margin: "0 0.5rem" }} /></div> */}
   
  </div> 
  
  <p className="mt-4">Indo Zambia Bank has set aside funds for the procurement of Solar Hybrid Inverter and Battery System across its Branch network and would like to apply part of the funds to cover eligible payments and the contract for: TENDER FOR THE DESIGN, INSTALLATION AND COMMISSIONING OF SOLAR HYBRID INVERTER AND BATTERY SYSTEM ACROSS IZB BRANCH NETWORK- IZB/CSA/01/07/2024.</p>
  <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/canceled.pdf">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "center" }}>
      <br/>
    Click here to download the Revised Solicitation Document
    </h5>
  </a>
  
</div>
      <section>
      <div className="container">
        <div className="row">
          <div className="container">
            <div className="heading ">
              <h2 className="text-left" style={{color:"#9E1B1E",fontWeight:"450"}}>More to Explore</h2>
             
            </div>
            <div
              className="py-4"
              style={{ float: "right"}}
            >
         
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
                <a href="/Workerscopensation">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie21} alt="" />
           
                    <div className="overlay-icon"></div>
              
                </div>
              </div>
              </a>










              
              <div className="text-holder">
                <h3 className="blog-title mt-4">
             
                  <a>IZB - Workers Compensation Fund Control Board Zambia E-Workers Smart Pay Launch
</a><br/>

                  <span style={{fontSize: "15px", color: "gray"}}>October 20, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                Indo Zambia Bank is proud to announce the launch of the provision of the E-Workers on our INDO Internet Banking platform .This INDO Net Banking platform ...
                </p><br/>
                <Link
                to="/Workerscopensation">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
           <a href="/Indopremierbanking">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie5} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a>Introducing Indo Premier Banking Suite - Tailored To Meet Your Banking Needs</a><br/>
                  <span style={{fontSize: "15px", color: "gray"}}>July 15, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                We cordially invite you to the Indo Premier Banking Suite. 
At Indo Zambia Bank (IZB), we understand and appreciate that you, our valued customers ...
                </p><br/>
                <Link
                to="/Indopremierbanking">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
             <a href="/Officiallaunchofizbhq">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie2} alt="" />
           
                    <div className="overlay-icon"></div>
              
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a>The Official Launch Of The New Indo House Corporate Head Office</a><br/>
                  <span style={{fontSize: "15px", color: "gray"}}>July 6, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                The Board, Management and Staff of the Indo Zambia Bank wish to extend their sincere gratitude to the President of the Republic of Zambia ...
                </p><br/>
                <Link
                to="/Officiallaunchofizbhq">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
  
         
        </div>
      </div>
    </section>

    
      <AllAcountsSection />
    </>
  );
};

export default Invitationn;
