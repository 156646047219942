import React from 'react'
import "../Hero/Hero.css"

const EmptySpace = () => {
  
  return (
    <div className='container-fluid Spacer'>
    </div>
  )
}

export default EmptySpace