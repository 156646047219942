import React, { useState } from "react";

const AtmLocations = () => {
  const formControl = {
    width: "600px",
    fill: "none",
    background: "transparent",
    border: "none",
    borderBottom: "1px solid grey",
    fontSize: "18px",
    // marginBottom: "16px",
    placeholder: {
      color: "#FFF",
    },
  };


  const [resultsFound, setResultsFound] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState(""); // Default selected radio button
  // const [searchQuery, setSearchQuery] = useState("");

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    search();
  };

  const search = () => {
    // Replace this with your search logic
    console.log("Searching for:", searchQuery);
    // Perform the search operation or submit the form
  };


  const atmLocations = [
    { id: "atm1", name: "KABWE", latitude: -14.442087, longitude: 28.442701},
    { id: "atm2", name: "CHILILABOMBWE", latitude: -12.37346, longitude: 27.84943},
    { id: "atm3", name: "CHOMA", latitude: -16.808295, longitude: 26.986044},
    { id: "atm4", name: "SOLWEZI", latitude: -12.183087, longitude: 26.400194},
    { id: "atm5", name: "KASAMA", latitude: -10.20651, longitude: 31.189829},
    { id: "atm6", name: "CHINSALI", latitude: -10.55418, longitude: 32.067462},
    { id: "atm7", name: "JACARANDA", latitude: -13.0046597, longitude: 28.6495241},
    { id: "atm8", name: "CROSSROADS", latitude: -15.440009, longitude: 28.352165},
    { id: "atm9", name: "MANSA", latitude: -11.199953, longitude: 28.888692},
    { id: "atm10", name: "MONGU", latitude: -15.267231, longitude: 23.134422},
    { id: "atm11", name: "KAFUE", latitude: -15.76232, longitude: 28.178207},
    { id: "atm12", name: "CHILENJE", latitude: -15.446731, longitude: 28.349664},
    { id: "atm13", name: "ZIMBA", latitude: -17.317557, longitude: 26.202557},
    { id: "atm14", name: "SERENJE", latitude: -13.229222, longitude: 30.231121},
    { id: "atm15", name: "LUNDAZI", latitude: -12.296232, longitude: 33.169537},
    { id: "atm16", name: "MUNGWI", latitude: -10.171875, longitude: 31.364117},
    { id: "atm17", name: "MULUNGUSHI AGENCY", latitude: -14.295823, longitude: 28.559507},
    { id: "atm18", name: "CHILANGA", latitude:  -15.5499940, longitude: 28.2727750},
    { id: "atm19", name: "KAMWALA", latitude: -15.426708, longitude: 28.288873},
    { id: "atm20", name: "LUSAKA MAIN", latitude: -15.423000, longitude: 28.282964},
    { id: "atm21", name: "NORTH END", latitude: -15.410916, longitude: 28.286829},
    { id: "atm22", name: "NDOLA", latitude: -12.973012, longitude: 28.648838},
    { id: "atm23", name: "KITWE", latitude: -12.8203137, longitude: 28.2121014},
    { id: "atm24", name: "CHINGOLA", latitude: -12.5443054, longitude: 27.8640157},
    { id: "atm25", name: "LIVINGSTONE", latitude: -17.844162, longitude: 25.85981},
    { id: "atm26", name: "INDUSTRIAL", latitude: -15.416385, longitude: 28.269564},
    { id: "atm27", name: "CHIPATA", latitude: -13.64087, longitude: 32.644686},
    { id: "atm28", name: "CHAWAMA", latitude: -15.456782, longitude: 28.267578},
    { id: "atm29", name: "MANDA HILL", latitude: -15.378584, longitude: 28.326234},
    { id: "atm30", name: "NYIMBA", latitude: -14.556617, longitude: 30.831301},
    { id: "atm31", name: "CHANDWE MUSONDA", latitude: -15.416385, longitude: 28.283371},
    // Add more ATM locations as needed
  ];

  const branchLocations = [
    { id: "branch1", name: "KABWE", latitude: -14.442087, longitude: 28.442701},
    { id: "branch2", name: "CHILILABOMBWE", latitude: -12.37346, longitude: 27.84943},
    { id: "branch3", name: "CHOMA", latitude: -16.808295, longitude: 26.986044},
    { id: "branch4", name: "SOLWEZI", latitude: -12.183087, longitude: 26.400194},
    { id: "branch5", name: "KASAMA", latitude: -10.20651, longitude: 31.189829},
    { id: "branch6", name: "CHINSALI", latitude: -10.55418, longitude: 32.067462},
    { id: "branch7", name: "JACARANDA", latitude: -13.0046597, longitude: 28.6495241},
    { id: "branch8", name: "CROSSROADS", latitude: -15.440009, longitude: 28.352165},
    { id: "branch9", name: "MANSA", latitude: -11.199953, longitude: 28.888692},
    { id: "branch10", name: "MONGU", latitude: -15.267231, longitude: 23.134422},
    { id: "branch11", name: "KAFUE", latitude: -15.76232, longitude: 28.178207},
    { id: "branch12", name: "CHILENJE", latitude: -15.446731, longitude: 28.349664},
    { id: "branch13", name: "ZIMBA", latitude: -17.317557, longitude: 26.202557},
    { id: "branch14", name: "SERENJE", latitude: -13.229222, longitude: 30.231121},
    { id: "branch15", name: "LUNDAZI", latitude: -12.296232, longitude: 33.169537},
    { id: "branch16", name: "MUNGWI", latitude: -10.171875, longitude: 31.364117},
    { id: "branch17", name: "MULUNGUSHI AGENCY", latitude: -14.295823, longitude: 28.559507},
    { id: "branch18", name: "CHILANGA", latitude:  -15.5499940, longitude: 28.2727750},
    { id: "branch19", name: "KAMWALA", latitude: -15.426708, longitude: 28.288873},
    { id: "branch20", name: "LUSAKA MAIN", latitude: -15.423000, longitude: 28.282964},
    { id: "branch21", name: "NORTH END", latitude: -15.410916, longitude: 28.286829},
    { id: "branch22", name: "NDOLA", latitude: -12.973012, longitude: 28.648838},
    { id: "branch23", name: "KITWE", latitude: -12.8203137, longitude: 28.2121014},
    { id: "branch24", name: "CHINGOLA", latitude: -12.5443054, longitude: 27.8640157},
    { id: "branch25", name: "LIVINGSTONE", latitude: -17.844162, longitude: 25.85981},
    { id: "branch26", name: "INDUSTRIAL", latitude: -15.416385, longitude: 28.269564},
    { id: "branch27", name: "CHIPATA", latitude: -13.64087, longitude: 32.644686},
    { id: "branch28", name: "CHAWAMA", latitude: -15.456782, longitude: 28.267578},
    { id: "branch29", name: "MANDA HILL", latitude: -15.378584, longitude: 28.326234},
    { id: "branch30", name: "NYIMBA", latitude: -14.556617, longitude: 30.831301},
    { id: "branch31", name: "CHANDWE MUSONDA", latitude: -15.416385, longitude: 28.283371},
    { id: "branch32", name: "LONGACRES", latitude: -15.410556, longitude: 28.318722},
    { id: "branch33", name: "LEWANIKA", latitude: -15.444686, longitude: 28.345942},

  ];
  const agencyLocations = [
    { id: "agency1", name: "Pachalo", address: "Outside Chipata Branch", latitude: 28.295964, longitude: -15.440876},
    { id: "agency2", name: "Cactus Money Transfers", address: "Libala SDA", latitude: -15.43969, longitude: 28.309794},
    { id: "agency3", name: "Godfrey Muzilika", address: "Chawama Roman Catholic", latitude: -15.460215, longitude: 28.280265},
    { id: "agency4", name: "Mike Mwiba", address: "Opposite Chawama Police Station", latitude: -15.464454, longitude: 28.283446},
    { id: "agency5", name: " Elkacha Enterprise", address: "Patuka Road Chawama", latitude: -15.466705, longitude: 28.284285},
    { id: "agency6", name: "Icon Financial Services (Emmanuel Zulu) ", address: "Chawama Market ", latitude: -15.462263, longitude: 28.283614},
    { id: "agency7", name: "Stephen Mumba", address: "Down Town Lusaka", latitude: -15.427127, longitude: 28.284443},
    { id: "agency8", name: "Eric Tembo", address: "Kanele Mall", latitude: -15.427032 , longitude: 28.28319},
    { id: "agency9", name: " Deria Tembo", address: "Kanele Mall", latitude: -15.426899, longitude: 28.282823},
    { id: "agency10", name: "Ruth Nakazwe", address: "KaiKai", latitude: -15.426733, longitude: 28.283545},
    { id: "agency11", name: "Chola Mutale", address: " Kanele Mall", latitude: -15.427129, longitude: 28.283026},
    { id: "agency12", name: "Debra Chiselama ", address: " Kanele Mall", latitude: -15.427107, longitude: 28.283138},
    { id: "agency13", name: "Reuben Kabwe", address: "  Down Town", latitude: -15.427130, longitude: 28.283552},
    { id: "agency14", name: "Abel Nyirongo", address: "  Down Town", latitude: -15.427604, longitude: 28.283927},
    { id: "agency15", name: "Joe Mtonga", address: "  Down Town", latitude: -15.427565, longitude: 28.283904},
    { id: "agency16", name: "Deophine Makunka", address: "East Park Mall", latitude: -15.392311, longitude: 28.322751},
    { id: "agency17", name: "Deophine Makunka", address: "Long Acres", latitude: -15.417680, longitude: 28.313870},
    { id: "agency18", name: "Deophine Makunka", address: "Long Acres", latitude: -15.417680, longitude: 28.313870},
    { id: "agency19", name: "Priscah Siantumbu", address: "Chipata First Bus Stop, Off Kasangula", latitude: -15.369353, longitude: 28.291725},
    { id: "agency20", name: "John Besa Trading", address: "Chipata First Bus Stop, Off Kasangula", latitude: -15.368620, longitude: 28.292572},
    { id: "agency21", name: "Packson Chishimba", address: "Chipata Road off Kasangula, opposite Bola Bet", latitude: -15.365201, longitude: 28.295535},
    { id: "agency22", name: "Esby Mambwe", address: "Chilanga", latitude: -15.361274, longitude: 28.298858},
    { id: "agency23", name: "Kalonga Bobby Chambwa", address: "Chilanga", latitude: -15.3255, longitude: 28.1606},
    { id: "agency24", name: "Walmac Solutions", address: "Chilanga", latitude: -15.555156, longitude: 28.273071},
    { id: "agency25", name: "Walmac Solutions", address: "Near Post office Chilanga", latitude: -15.555156, longitude: 28.273071},
    { id: "agency26", name: "Lilfred Japan Ali", address: "Near Post office Chilanga", latitude: -15.557440, longitude: 28.274991},
    { id: "agency27", name: "Priscilla Kunda", address: "Near Post office Chilanga", latitude: -15.558148, longitude: 28.275115},
    { id: "agency28", name: "Squitech Digital Services", address: "Near Post office Chilanga", latitude: -15.557032, longitude: 28.274363},
    { id: "agency29", name: "Beatrice Habeenzu", address: "Near Post office Chilanga", latitude: -15.559270, longitude: 28.274212},
    { id: "agency30", name: "Aultony Lungu", address: "Near Post office Chilanga", latitude: -15.557418, longitude: 28.274062},
    { id: "agency31", name: "Bernard Sichinga", address: "Near Post office Chilanga", latitude: -15.555329, longitude: 28.272945},
    { id: "agency32", name: "Ian Binda", address: "Freedom Chilanga", latitude: -15.539664, longitude: 28.265873},
    { id: "agency33", name: "Samuel Chilulunda", address: "Near Livingstone Tourism Association", latitude: -17.851196, longitude: 25.854456},
    { id: "agency34", name: "Pristed General Dealers", address: "Lewanika Mall, Woodlands shop No. 30", latitude: -15.442768, longitude: 28.343165},
    { id: "agency35", name: "Peridot Investment Limited", address: "Outside Lewanika Mall stand No 3", latitude: -15.446048, longitude: 28.347255},
    { id: "agency36", name: "Noah Silwimba", address: "Chifundo Road Chawama Zambeef", latitude: -15.452340, longitude: 28.281018},
    { id: "agency37", name: "Lloyd Chota", address: "Snow White Chawama Zambeef", latitude: -15.453082, longitude: 28.274587},
    { id: "agency38", name: "Shepherd Chinamira", address: "Zambeef Chawama", latitude: -15.450423, longitude: 28.280772},
    { id: "agency39", name: "Charles Kabwe Ngoma", address: "Matero Shoprite", latitude: -15.382311, longitude: 28.282125},
    { id: "agency40", name: "Casten Mwape", address: "Matero CBI", latitude: -15.382418, longitude: 28.261942},
    { id: "agency41", name: "Esquire Enterprise LTD", address: "Matero Choppies", latitude: -15.384096, longitude: 28.262342},
    { id: "agency42", name: "Moono Kachota", address: "Muchinga Road Matero", latitude: -15.381341, longitude: 28.256960},
    { id: "agency43", name: "Shane Twaingwa", address: "Muchinga Bus Station", latitude: -15.380952, longitude: 28.256653},
    { id: "agency44", name: "Pevory Siame", address: "Matero Bridge", latitude: -15.378904, longitude: 28.249225},
    { id: "agency45", name: "Brayo General Dealers", address: "Matero Bridge", latitude: -15.379877, longitude: 28.249374},
    { id: "agency46", name: "Boyd Mike Ngulube", address: "Lilanda Bus Stop", latitude: -15.378170, longitude: 28.243249},
    { id: "agency47", name: "Elias Simumba", address: "Lilanda Bus Stop", latitude: -15.378363, longitude: 28.243050},
    { id: "agency48", name: "Steward Chibutu", address: "Desai Matero", latitude: -15.370178, longitude: 28.250142},
    { id: "agency49", name: "Kenneth Shachiinda Chindwe Universal Church", address: "Universal church", latitude: -15.425895, longitude: 28.283964},
    { id: "agency50", name: "Lizzy Chatengwa", address: "Simon Mwewa Road", latitude: -15.423928, longitude: 28.278156},
    { id: "agency51", name: "Margarita Chungu", address: "Rumumba Road", latitude: -15.423996, longitude: 28.278275},
    { id: "agency52", name: "Ganizani Miti", address: "Saint Monica Road Kingdom Hall", latitude: -15.447431, longitude: 28.232623},
    { id: "agency53", name: "Fredrick Chibale", address: "Near to total filling station Chinika", latitude: -15.416768, longitude: 28.266580},
    { id: "agency54", name: "Nicholas Chikwanda", address: "Near to Chaba Standard Training, Lake petroleum filling station", latitude: -15.423136, longitude: 28.244719},
    { id: "agency55", name: "Joseph Mutale", address: "Kanyama West Urban Health Centre", latitude: -15.425950, longitude: 28.235429},
    { id: "agency56", name: "Prince Wila Enterprise", address: "Garden House Market Area", latitude: -15.412634, longitude: 28.248477},
    { id: "agency57", name: "Mutale Nathan", address: "Near AMG Construction, behind Trade King's Warehouse", latitude: -15.413708, longitude: 28.248566},
    { id: "agency58", name: "Melvin Simwaba", address: "Makeni Simpson, Near Makeni Mosque Police Station", latitude: -15.446680, longitude: 28.249342},
    { id: "agency59", name: "Shilis General Dealers", address: "Garden House Market", latitude: -15.420745, longitude: 28.224769},
    { id: "agency60", name: "Veronica Cash Express", address: "Chawama Bus Station", latitude: -15.460301, longitude: 28.283746},
    { id: "agency61", name: "Chimuka Kasisi", address: "Chawama Market", latitude: -15.460769, longitude: 28.284019},
    { id: "agency62", name: "Binwell Kajimoto", address: "Chawama Market", latitude: -15.460736, longitude: 28.283909},
    { id: "agency63", name: "Ladlous Daka", address: "Chawama Anglican", latitude: -15.463335, longitude: 28.282409},
    { id: "agency64", name: "Patuka General Dealers", address: "Near Chawama Police Station", latitude: -15.466214, longitude: 28.284256},
    { id: "agency65", name: "Evans Kabwita", address: "Near Chokocha Kuomboka", latitude: -15.462195, longitude: 28.275547},
    { id: "agency66", name: "Justin Sakala", address: "Near Chawama Complex shopping centre", latitude: -15.451246, longitude: 28.286968},
    { id: "agency67", name: "Shane Twaingwa", address: "Shane Twaingwa", latitude: -15.380952, longitude: 28.256653},
    { id: "agency68", name: "Izyane Mobile Agency", address: "Lusaka", latitude: 12.826092, longitude: 28.215530},
    { id: "agency69", name: "Angela Kasoma", address: "Ndola town centre next to Stanbic ban", latitude: -12.972132, longitude: 28.650224},
    { id: "agency70", name: "Thompson Chipata", address: "Garden fred Joe", latitude: -15.397305, longitude: 28.291945},
    { id: "agency71", name: "Mavelous Muchindu", address: "DOWNTOWN", latitude: -15.428012, longitude: 28.283138},
    { id: "agency72", name: "Suwilanda Mwangonda", address: "Emmas Comer 71 ,Libala South", latitude: -15.463556, longitude: 28.318127},
    { id: "agency73", name: "Jacob Siame", address: "John Howard CHIFUNDO RD", latitude: -15.401857, longitude: 28.354229},
    { id: "agency74", name: "Leebec General Dealers", address: "Eden University", latitude: -15.363115, longitude: 28.233275},
    { id: "agency75", name: "George Mulenga", address: "Zingalume Simbeye", latitude: -15.371474, longitude: 28.234541},
    { id: "agency76", name: "Lazarus Banda", address: "Lilanda Twikatane Road Opposite Catholic", latitude: -15.371640, longitude: 28.241150},
    { id: "agency77", name: "Innocent Chilongo", address: "Along Muramba Road Behind IZB CHILENJE Branch", latitude: -15.450540, longitude: 28.347097},
    { id: "agency78", name: "Alfred Mwale", address: "Indo Chilenje Branch", latitude: -15.450123, longitude: 28.347361},
    { id: "agency79", name: "Nguza Nyondo", address: "IZB CHILENJE Branch", latitude: -15.4505948, longitude: 28.3472449},
    { id: "agency80", name: "Abraham Tembo", address: "Along Kamwala South road near St Patrick's Station", latitude: -15.4360989 ,longitude: 28.2983109},
    { id: "agency81", name: "Petros Mazunda", address: "Along Paul Ngozi Road Kabwata Near Bob's Corner", latitude: -15.44636,longitude: 28.2932996},
    { id: "agency82", name: "Comfort Chishala Nyondo", address: "Engen fueling station along Paul Ngozi Road Kabwata", latitude: -15.4433783,longitude: 28.3417001},
    { id: "agency83", name: "Gerald Sichone", address: "Paul Ngozi St, Lusaka", latitude: -15.440876, longitude: 28.295964},
    { id: "agency84", name: "KENNEDY NGOMA", address: "Los Angeles Rd", latitude: -15.4330564,longitude: 28.2552883},
    { id: "agency85", name: "KENT PHIRI", address: "KALUSHA BWALYA", latitude: -15.439269, longitude: 28.254482},
    { id: "agency86", name: "Caroline Mwamba", address: "Chilenje", latitude: -15.4456619,longitude: 28.3429603},
    { id: "agency87", name: "Zipora Chila", address: "Down Town, Bus Station", latitude: -15.4295608,longitude: 28.2831634},
    { id: "agency88", name: "Gideon Njobvu", address: "Down Town, Along Kafue Road", latitude: -15.4283020,longitude: 28.02834890},
    { id: "agency89", name: "Daniel Ntele", address: "Outside UTH", latitude: -15.4312848,longitude: 28.3121976},
    { id: "agency90", name: "Felix Kachasu", address: "Chunga Bus Stop", latitude: 15.360960, longitude: 28.250473},
    { id: "agency91", name: "Wamunyima Sikwibele", address: "Chunga Police", latitude: -15.36890852,longitude: 28.26310158},
    { id: "agency92", name: "Kemz General Dealers", address: "Matero East", latitude: -15.3742046, longitude: 28.2734133},
    { id: "agency93", name: "Hoster Chiyoba", address: "Eden University", latitude: -15.363082,longitude: 28.2327693},
    { id: "agency94", name: "Kelvin Chama", address: "Mtendere", latitude: -15.4017332,longitude: 28.3546258},
    { id: "agency95", name: "Elvera Hamusonde", address: "Chima Mobile Town Area", latitude: -17.847139, longitude: 25.857111},
    { id: "agency96", name: "William Chabala", address: "Snow White Market, Lusaka", latitude: -15.451674, longitude: 28.280986},
    { id: "agency97", name: "Abel Zulu", address: "Snow White Market, Lusaka", latitude: 15.451673, longitude: 28.280985},
    { id: "agency98", name: "Abel Kunda", address: "Masala Market", latitude: "", longitude: ""},
    { id: "agency99", name: "Sarah Nkhoma", address: "Masala Market", latitude: -12.97939973, longitude: 28.65111195},
    { id: "agency100", name: "Quince Mwenya", address: "Freedom Way, Malela", latitude: "", longitude: ""},
    { id: "agency101", name: "Chongo Katapa", address: "Town Center", latitude: "", longitude: ""},
    { id: "agency102", name: "Brian Chivuna", address: "Malata Butchery, Kasama", latitude: "", longitude: ""},
    { id: "agency103", name: "Daniel Chimwisha", address: "Nchanga North Hospital Chingola", latitude: "", longitude: ""},
    { id: "agency104", name: "Christine Mulenga", address: "Town Centre", latitude: "", longitude: ""},
    { id: "agency105", name: "Fridah Mwandu Chunsu", address: "Town Center, Chingola", latitude: "", longitude: ""},
    { id: "agency106", name: "Bukali Victor", address: "Chingola Town Centre", latitude: "", longitude: ""},
    { id: "agency107", name: "Discovery Education Ltd", address: "Chingola Town Centre, Opposite ZANACO", latitude: "", longitude: ""},
    { id: "agency108", name: "Andrew Kaunda", address: "Masala Market", latitude: "", longitude: ""},
    { id: "agency109", name: "Jomuga Enterprises", address: "", latitude: "", longitude: ""},
    { id: "agency110", name: "Family and Friend's Shop", address: "", latitude: -10.201558 , longitude: 31.193833},
    { id: "agency111", name: "Jaburane Enterpries", address: "New Twapia Market, Ndola", latitude: 12.9923672, longitude: 28.5977219},
    { id: "agency112", name: "Allan Mwansa", address: "Town Center, Chingola", latitude: -12.5249667, longitude: 27.8730883},
    { id: "agency113", name: "Ignitius Mwape", address: "Chimwemwe, Opposite UCZ, Kitwe", latitude: "", longitude: ""},
    { id: "agency114", name: "David Phiri", address: "Old Twapia Market", latitude: -12.7751133, longitude: 28.2032667},
    { id: "agency115", name: "Golden Pritchards Banda", address: "Town Centre", latitude: -12.5436731, longitude: 27.8640335},
    { id: "agency116", name: "Stephen Chileya", address: "Chingola Town Centre", latitude: -12.5436730, longitude: 27.8640334},
    { id: "agency117", name: "Prince Kampamba", address: "Chingola Bus Station", latitude: 12.5440144, longitude: 27.8644823},
    { id: "agency118", name: "Prince Kampamba", address: "Chingola Bus Station", latitude: 12.5440144, longitude: 27.8644823},
    { id: "agency119", name: "Martin Kabwe", address: "Chingola Town Centre", latitude: -12.5434041 , longitude: 27.864745},
    { id: "agency120", name: "Martin Kabwe", address: "Chingola Town Centre", latitude: -12.5434041 , longitude: 27.864745},
    { id: "agency121", name: "Martin Kabwe", address: "Chingola Town Centre", latitude: -12.5434041 , longitude: 27.864745},
    { id: "agency122", name: "Aminon Kwenda", address: "Saturday Market", latitude: -13.6373253, longitude: 32.6459961},
    { id: "agency123", name: "Esther Mbewe", address: "Chipata General Hospital", latitude: -13.6416548, longitude: 32.6382348},
    { id: "agency124", name: "Naphtali Sakala", address: "", latitude: -13.6404419, longitude: 32.6421602},
    { id: "agency125", name: "Micheal Mwale", address: "", latitude: -13.6418117, longitude: 32.6462817},
    { id: "agency126", name: "Evan Mudolo", address: "", latitude: -13.6433854, longitude: 32.6466979},
    { id: "agency127", name: "Morgan Phiri", address: "Kapata Main Bus Station", latitude: -13.6329573, longitude: 32.6469522},
    { id: "agency128", name: "Mr. Phone Tech Solutions", address: "Kapata Market", latitude: -13.6330716, longitude: 32.6473252},
    { id: "agency129", name: "Eric Moyo", address: "Chipata Central Hospital", latitude: -13.6421717, longitude: 32.6388583},
    { id: "agency130", name: "Eric Moyo", address: "", latitude: -13.6421717, longitude: 32.6388583},
    { id: "agency131", name: "Eric Moyo", address: "", latitude: -13.6421717, longitude: 32.6388583},
    { id: "agency132", name: "Cactus Provident House", address: "Cairo Road", latitude: -15.42174781,longitude: 28.28307536},
    { id: "agency133", name: "Chiyembekezo", address: "Chipata", latitude: -15.557032 , longitude: 28.274363},
    { id: "agency134", name: "Cactus South Gate", address: "South Gate Mall", latitude: -15.42294465,longitude: 28.28026772},
    { id: "agency135", name: "Kennedy Shamaindi", address: "Oldies, Lusaka", latitude: -15.4541441, longitude: 28.2685868},
    { id: "agency136", name: "Stella Fulanse", address: "JCC, Lusaka", latitude: 15.4539724 , longitude: 28.270335},
    { id: "agency137", name: "Prudence Zimba", address: "JCC, Lusaka", latitude: -15.4539638, longitude: 28.2710457},
    { id: "agency138", name: "Fridah Muntemba", address: "JCC, Lusaka", latitude: -15.4538719, longitude: 28.2713359},
    { id: "agency139", name: "Shepherd Mususa", address: "Snow White Market, Lusaka", latitude: -15.4528889, longitude: 28.2807789},
    { id: "agency140", name: "Vivian Cheelo", address: "Chifundo Road, Kuku Compound", latitude: -15.4515723, longitude: 28.2815055},
    { id: "agency141", name: "Beatrice Kaoma", address: "Kuku Compound, Lusaka", latitude: -15.4490031, longitude: 28.2818789},
    { id: "agency142", name: "Justine Sakala", address: "Kuku Compound, Lusaka", latitude: -15.451137, longitude: 28.2870225},
    { id: "agency143", name: "Innaz Enterprise", address: "Close to Indo Zambia Bank", latitude: -14.4420883, longitude: 28.4422145},
    { id: "agency144", name: "Fredrick Mulenga", address: "Mwaiseni Bus Station", latitude: "", longitude: ""},
    { id: "agency145", name: "Hastings Munkwombwe", address: "Lusaka West", latitude: -15.4493283, longitude: 28.2241417},
    { id: "agency146", name: "Paul Banda", address: "Near IZB Chilenje Branch", latitude: -15.4499969, longitude: 28.3476116},
    { id: "agency147", name: "Cactus", address: "Luangwa House", latitude: "", longitude: ""},
    { id: "agency148", name: "Cactus Money Transfers", address: "Levy Mall", latitude: "", longitude: ""},
    { id: "agency149", name: "Elizabeth katiba", address: "Soweto Market", latitude: -15.4192627, longitude: 28.27548265},
    { id: "agency150", name: "Smart Pays Trading", address: "Katondo Street", latitude: -15.4170361, longitude: 28.2802553},
    { id: "agency151", name: "Rachel Mukuka", address: "Town", latitude: -15.4171647, longitude: 28.2800214},
    { id: "agency152", name: "Kennedy Kangwa", address: "Town", latitude: -15.4170011, longitude: 28.2804214},
    { id: "agency153", name: "Ruth Kamwana Kutiya", address: "Town", latitude: -15.4172367, longitude: 28.2786227},
    { id: "agency154", name: "Christine Mwanza", address: "Town, Lusaka", latitude: -15.4172189, longitude: 28.2786291},
    { id: "agency155", name: "Naomi Mwambula", address: "Town, Lusaka", latitude: -15.4224669, longitude: 28.2799276},
    { id: "agency156", name: "Martha Nambeya", address: "Town, Lusaka", latitude: -15.4235954, longitude: 28.2812717},
    { id: "agency157", name: "Sarah Ngoma", address: "Industrial Branch, Lusaka", latitude: -15.4167161, longitude: 28.2698758},
    { id: "agency158", name: "Gift Phiri", address: "Hillview, Libala South Lusaka", latitude: -15.4640342, longitude: 28.3184492},
    { id: "agency159", name: "Tizola Mwanza", address: "Lima Tower", latitude: -15.42385608, longitude: 28.28107975},
    { id: "agency160", name: "Milimo Mwiinga", address: "Down Town", latitude: -15.42801176, longitude: 28.28313835},
    { id: "agency161", name: "Fabiano Newa", address: "Freedom Way, Lusaka", latitude: -15.4159566, longitude: 28.2780948},
    { id: "agency162", name: "Dc Kapus General Dealers", address: "Kasanda New Market, Kabwe", latitude: -14.4439413, longitude: 28.4381249},
    { id: "agency163", name: "Shadreck Sikanyika", address: "", latitude: -15.4245852, longitude: 28.2864014},
    { id: "agency164", name: "Benjamin Manda", address: "Iringa Mall", latitude: -15.4244886, longitude: 28.2805296},
    { id: "agency165", name: "Gift Mendamenda", address: "Misisi Compound, Lusaka", latitude: "", longitude: ""},
    { id: "agency166", name: "Chishala Kennedy", address: "John Laing, Lusaka", latitude: -15.4399409, longitude: 28.2583792},
    { id: "agency167", name: "Happy Phiri", address: "Kanyama Lusaka", latitude: -15.4337788, longitude: 28.2546898},
    { id: "agency168", name: "Kedrick Yanika", address: "Los Angeles Rd, Lusaka", latitude: -15.431993, longitude: 28.2635462},
    { id: "agency169", name: "Walubita Mukelabai", address: "Kanyama, Lusaka", latitude: -15.42752664, longitude: 28.24008051},
    { id: "agency170", name: "Ennie Nyimba", address: "Outside Chipata Branch", latitude: -13.640193, longitude: 32.648613},
    { id: "agency171", name: "Peter P Mfune", address: "Outside Chipata Branch, Eastern Province", latitude: -13.6408314, longitude: 32.636993},
    { id: "agency172", name: "Jones Msimuko", address: "Opposite ABSA", latitude: -13.6407537, longitude: 32.6458359},
    { id: "agency173", name: "Jane Nyirenda", address: "Along Hospital Road", latitude: -13.6407537, longitude: 32.6458359},
    { id: "agency174", name: "Elina Banda", address: "Mandevu, Lusaka", latitude: -15.40209317, longitude: 28.284688},
    { id: "agency175", name: "Chanda Chengo", address: "Chingwere, Lusaka", latitude: -15.3718893, longitude: 28.2560867},
    { id: "agency176", name: "Fales Phiri", address: "Kamwala", latitude: -15.4246192, longitude: 28.2867657},
    { id: "agency177", name: "Maureen Banda", address: "Kamwala", latitude: -15.4117948, longitude: 28.2886838},
    { id: "agency178", name: "Peter Musonda", address: "TC Junction, Matero", latitude: -15.3757141, longitude: 28.2661753},
    { id: "agency179", name: "Vermark Bureau de Change", address: "East Park Mall, Lusaka", latitude: "", longitude: ""},
    { id: "agency180", name: "Haggai Liyanda", address: "", latitude: -15.4205303, longitude: 28.2229952},
    { id: "agency181", name: "Mary Kandala", address: "Garden House", latitude: -15.42069935, longitude: 28.22383773},
    { id: "agency182", name: "Joseph Peter Manda", address: "Chawama, Lusaka", latitude: -15.51120926, longitude: 28.27157736},
    { id: "agency183", name: "Phaides Chilelesho", address: "Kamwala, Lusaka", latitude: -15.42567505, longitude: 28.28863621},
    { id: "agency184", name: "Barbara Siakanungu", address: "Kamwala", latitude: -15.42567505, longitude: 28.28863621},
    { id: "agency185", name: "Janet Siambai", address: "Kamwala, Lusaka", latitude: -15.42581984, longitude: 28.28863621},
    { id: "agency186", name: "Violet Banda", address: "Kamwala, Lusaka", latitude: -15.42546478, longitude: 28.28951597},
    { id: "agency187", name: "Comfort Chishala Nyondo", address: "Kamwala, Lusaka", latitude: -15.4417444, longitude: 28.2960432},
    { id: "agency188", name: "Ojobanji Ngoma", address: "Chacha", latitude: -15.419689, longitude: 28.2804924},
    { id: "agency189", name: "Kasha Kaina", address: "Engen Fueling Station", latitude: -15.43723747, longitude: 28.35502625},
    { id: "agency190", name: "Janet Kayamba", address: "Crossroads", latitude: -15.43723747, longitude: 28.35333109},
    { id: "agency191", name: "Edinah Nambela", address: "Crossroads", latitude: -15.43723747, longitude: 28.35333109},
    { id: "agency192", name: "Visión Net Business Center", address: "Church House", latitude: -15.42140846, longitude: 28.28176841},
    { id: "agency193", name: "Bright Zulu", address: "Chawama, Lusaka", latitude: -15.4519894, longitude: 28.2802619},
    { id: "agency194", name: "Francis Phiri", address: "Chawama, Lusaka", latitude: -15.4512561, longitude: 28.286146},
    { id: "agency195", name: "Moses Tembo", address: "Town, Lusaka", latitude: -15.4194983, longitude: 28.2781313},
    { id: "agency196", name: "Hastings Kabimba", address: "Town, Lusaka", latitude: -15.4194983, longitude: 28.2781313},
    { id: "agency197", name: "Galeb Chisanga", address: "Town, Lusaka", latitude: -15.4212973, longitude: 28.2769787},
    { id: "agency198", name: "Pasco Moonga", address: "Town, Lusaka", latitude: -15.4139187, longitude: 28.2790443},
    { id: "agency199", name: "Menvan Mwapa", address: "Town, Lusaka", latitude: 15.4141758, longitude: 28.2782688},
    { id: "agency200", name: "Angela Sianyungwa", address: "Kamwala Town, Lusaka", latitude: -15.4248906, longitude: 28.2856476},
    { id: "agency201", name: "Alfred Mwale", address: "Chilenje, Lusaka", latitude: -15.4500468, longitude: 28.3475565},
    { id: "agency202", name: "Nyondo Nguza", address: "Chilenje, Lusaka", latitude: -15.4500268, longitude: 28.347577},
    { id: "agency203", name: "Lucia Mushaukwa", address: "Chilenje, Lusaka", latitude: -15.4500268, longitude: 28.347577},
    { id: "agency204", name: "Natasha Zulu", address: "Town, Lusaka", latitude: -15.42105197, longitude: 28.27514034},
    { id: "agency205", name: "Harrison Mpanga", address: "Masauko Road", latitude: -15.4376925, longitude: 28.24929055},
    { id: "agency206", name: "Nora Namusokwe", address: "Mchini, Chipatta", latitude: -13.65486695, longitude: 32.64876261},
    { id: "agency207", name: "BIONIC KABWE", address: "CITY MARKET", latitude: -15.4214197, longitude: 28.27808},
    { id: "agency208", name: "RANKING KOMBE", address: "BENBELA ROAD", latitude: -15.4253172, longitude: 28.2796422},
    { id: "agency209", name: "BENADETTE MULENGA", address: "ALONG CAIRO ROAD", latitude: -15.4107421, longitude: 28.2828778},
    { id: "agency210", name: "SIMON CHITOSHI", address: "", latitude: -15.424295, longitude: 28.2813383},
    { id: "agency211", name: "Gideon Kasichi", address: "", latitude: -15.5128527, longitude: 28.3000355},
    { id: "agency212", name: "Tashiji Trading", address: "", latitude: -15.42443558, longitude: 28.28105696},
    { id: "agency213", name: "Verock General Dealers", address: "Chawama", latitude: -15.4600301, longitude: 28.2820823},
    { id: "agency214", name: "Elijah Hajanika", address: "Protea Road", latitude: -15.41419473, longitude: 28.29314232},
    { id: "agency215", name: "Mwale Naomi", address: "Kamwala", latitude: -15.41419473, longitude: 28.29314232},
    { id: "agency216", name: "Esther Chanda", address: "Kamwala", latitude: -15.41419473, longitude: 28.29314232},
    { id: "agency217", name: "Esther Chanda", address: "Kamwala", latitude: -15.41419473, longitude: 28.29314232},
    { id: "agency218", name: "Previous Muchaili", address: "Intercity", latitude: -15.4216, longitude: 28.286153},
    { id: "agency219", name: "Esther Mbuzi", address: "Kamwala", latitude: -15.41382238, longitude: 28.29359293},
    { id: "agency220", name: "Steward Chibutu", address: "Matero", latitude: -15.3700534, longitude: 28.2502222},
    

    // Add more agency locations as needed
  ];
  const handleDirectionClick = (latitude, longitude) => {
    const url = `https://maps.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, "_blank");
  };
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim() === "") {
      return; // Don't perform search if the search query is empty or contains only whitespace
    }

    let filteredLocations = [];

    if (selectedRadio === "atm") {
      filteredLocations = atmLocations.filter((location) => {
        const formattedName = location.name.replace(/\s/g, "").toLowerCase();
        const formattedLatitude = location.latitude.toString().replace(/\s/g, "").toLowerCase();
        const formattedLongitude = location.longitude.toString().replace(/\s/g, "").toLowerCase();
  
        return (
          formattedName.includes(searchQuery.replace(/\s/g, "").toLowerCase()) ||
          formattedLatitude.includes(searchQuery.replace(/\s/g, "").toLowerCase()) ||
          formattedLongitude.includes(searchQuery.replace(/\s/g, "").toLowerCase())
        );
      });
    } else if (selectedRadio === "branch") {
      filteredLocations = branchLocations.filter((location) => {
        const formattedName = location.name.replace(/\s/g, "").toLowerCase();
        const formattedLatitude = location.latitude.toString().replace(/\s/g, "").toLowerCase();
        const formattedLongitude = location.longitude.toString().replace(/\s/g, "").toLowerCase();
  
        return (
          formattedName.includes(searchQuery.replace(/\s/g, "").toLowerCase()) ||
          formattedLatitude.includes(searchQuery.replace(/\s/g, "").toLowerCase()) ||
          formattedLongitude.includes(searchQuery.replace(/\s/g, "").toLowerCase())
        );
      });
    } else if (selectedRadio === "agency") {
      filteredLocations = agencyLocations.filter((location) => {
        const formattedName = location.name.replace(/\s/g, "").toLowerCase();
        const formattedAddress = location.address.replace(/\s/g, "").toLowerCase();
        const formattedLatitude = location.latitude.toString().replace(/\s/g, "").toLowerCase();
        const formattedLongitude = location.longitude.toString().replace(/\s/g, "").toLowerCase();
  
        return (
          formattedName.includes(searchQuery.replace(/\s/g, "").toLowerCase()) ||
          formattedAddress.includes(searchQuery.replace(/\s/g, "").toLowerCase()) ||
          formattedLatitude.includes(searchQuery.replace(/\s/g, "").toLowerCase()) ||
          formattedLongitude.includes(searchQuery.replace(/\s/g, "").toLowerCase())
        );
      });
    }
  
    setSearchResults(filteredLocations);
    if (filteredLocations.length > 0) {
      setResultsFound(true);
    } else {
      setResultsFound(false);
    }
  };

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
    setSearchResults([]); // Reset search results when switching radio buttons
  };


  return (
    <div className="container p-4 ">
      <div className="row">
        <div className="text-center mb-5">
          <h1 style={{ color: "#484848" }}>ATM/Branch/Agency Location </h1>
          <p style={{ color: "#484848" }}>
            Locate Indo Zambia Bank Branch and our ATM locations in your area.
          </p>
        </div>

        <div className="row d-flex justify-content-center justify-content-sm-start align-items-center gap-4 p-3" style={{ backgroundColor: "#9E1B1E", marginLeft:"0.1rem" }}>
  <div className="col-sm-auto">
    <div className="form-check">
      <input
        className="form-check-input"
        type="radio"
        name="flexRadioDefault"
        id="flexRadioDefault1"
        value="branch"
        checked={selectedRadio === "branch"}
        onChange={(e) => setSelectedRadio(e.target.value)}
      />
      <label
        className="text-white form-check-label"
        htmlFor="flexRadioDefault1"
      >
        BRANCH
      </label>
    </div>
  </div>
  <div className="col-sm-auto">
    <div className="form-check">
      <input
        className="form-check-input"
        type="radio"
        name="flexRadioDefault"
        id="flexRadioDefault2"
        value="atm"
        checked={selectedRadio === "atm"}
        onChange={(e) => setSelectedRadio(e.target.value)}
      />
      <label
        className="text-white form-check-label"
        htmlFor="flexRadioDefault2"
      >
        ATM
      </label>
    </div>
  </div>
  <div className="col-sm-auto">
    <div className="form-check">
      <input
        className="form-check-input"
        type="radio"
        name="flexRadioDefault"
        id="flexRadioDefault3"
        value="agency"
        checked={selectedRadio === "agency"}
        onChange={(e) => setSelectedRadio(e.target.value)}
      />
      <label
        className="text-white form-check-label"
        htmlFor="flexRadioDefault3"
      >
        AGENCY
      </label>
    </div>
  </div>
  <div className="col-sm">
    <form role="form" className="form-horizontal" onSubmit={handleSearch}>
      <div className="form-group has-feedback has-feedback-left">
        <label className="control-label sr-only">Username</label>
        <input
          type="text"
          id="HomepageAtm"
          className="form-control w-100"
          style={formControl}
          placeholder="Enter Your Location"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
       
        />
      </div>
    </form>
 
  </div>
  <div className="col-sm-auto">
  <button
    className="btn btn-light my-3 rounded"
    type="button"
    onClick={handleSearch}
    style={{
      cursor: searchQuery.trim() === "" ? "not-allowed" : "pointer",
      backgroundColor: "white!important",
    }}
    disabled={searchQuery.trim() === ""}
  >
    Search
  </button>
</div>

</div>

      </div>

      {/* Render the search results */}
      {resultsFound ? (
  <div>
    {searchResults.map((location) => (
      <div key={location.id}>
        <p>
          {selectedRadio === "branch"
            ? "Branch Name"
            : selectedRadio === "atm"
            ? "ATM Name"
            : "Agent Name"}: {location.name}
        </p>
        {selectedRadio === "agency" && <p>Location: {location.address}</p>}
        {/* <p>Longitude: {location.longitude}</p>
        <p>Latitude: {location.latitude}</p> */}
        <button
            className="btn btn-link"
            onClick={() =>
              handleDirectionClick(location.latitude, location.longitude)
            } style={{border:"1px solid #9e1b1e",backgroundColor:"#9e1b1e",textDecoration:"none",color:"#fff",margin:"1vw"}}
          >
            Direction
          </button>
     
        
        <hr />
      </div>
    ))}
  </div>
) : (
  <p>No results found.</p>
)}

    </div>
  );
};

export default AtmLocations;